<template>
  <div class="icon-styling"
       @mouseover="showingPopover = true"
       @mouseleave="showingPopover = false"
       @click="showSavedSearchesModal">
    <archive-icon/>
    <custom-pop-over
        :left="-200"
        v-if="showingPopover">
      All Saved Searches
    </custom-pop-over>
  </div>
</template>

<script>
import CustomPopOver from "@/components/CustomPopOver";
import {ArchiveIcon} from "vue-feather-icons";

export default {
  name: "SavedSearches",
  components: {
    CustomPopOver,
    ArchiveIcon
  },

  data() {
    return {
      showingPopover: false
    }
  },

  methods: {
    showSavedSearchesModal() {
      this.$store.dispatch('collections/setShowingModal', true);
      this.$store.dispatch('collections/setCountLoad',true);
      this.$store.dispatch('collections/fetchAllCounts')
    }
  }

}
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./Include.vue?vue&type=template&id=50f0c8dd&scoped=true"
import script from "./Include.vue?vue&type=script&lang=js"
export * from "./Include.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50f0c8dd",
  null
  
)

export default component.exports
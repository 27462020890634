<template>
  <div class="icon-styling"
       @mouseover="showingPopover = true"
       @mouseleave="showingPopover = false"
       data-bs-toggle="tooltip"
       data-bs-placement="top" title="Download report">
    <sliders-icon @click="openSidebar" />

    <custom-pop-over
        :left="-20"
        v-if="showingPopover">
      Open Filters Sidebar
    </custom-pop-over>
  </div>
</template>

<script>
import CustomPopOver from "@/components/CustomPopOver";
import {SlidersIcon} from 'vue-feather-icons';
import {mapActions, mapGetters} from "vuex";

export default {
name: "OpenFiltersSidebar",
  components: {CustomPopOver, SlidersIcon},

  data() {
    return {
      showingPopover: false
    }
  },

  computed: {
    ...mapGetters('filters', ['getFiltersSidebarOpenState'])
  },

  methods: {
    ...mapActions('filters', ['openFiltersSidebar']),

    openSidebar() {
      this.openFiltersSidebar(!this.getFiltersSidebarOpenState);
    }
  }

}
</script>

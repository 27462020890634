<template>
  <div class="all-collections">
    <div class="collection-items">
      <div v-if="returnIsLoading">
        Loading...
      </div>
      <div v-else>
        <div v-if="allCuratedCollections.length <= 0">
          <small>You don't have any shared searches...</small>
        </div>
        <div v-for="collectionItem in allCuratedCollections" v-else :key="collectionItem.id">
          <company-search-row :collection-item="collectionItem" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CompanySearchRow from "@/components/saved-searches-modal/CompanySearchRow";

export default {
  name: "AllCompanySearches",
  components: { CompanySearchRow },
  computed: {
    allCuratedCollections: function () {
      return this.$store.getters["collections/returnAllCompanyCollections"];
    },
    ...mapGetters('collections', ['returnIsLoading'])
  }

}
</script>

<style scoped>
.all-collections {
  padding: 10px 10px;
  width: 100%;
}
</style>
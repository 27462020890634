<template>
  <div class="my-3">
    <p class="mb-0 font-weight-bold">Agency</p>
    <v-select
      multiple
      label="name"
      :options="allAgencies"
      :value="selectedItems"
      @input="updateTags"
      :select-on-tab="true"
      :close-on-select="false"
      :clearSearchOnSelect="false"
    >
    </v-select>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { addOrUpdateParameter } from "@/helpers/urlParamsHelper";

export default {
  name: "Agencies",

  components: {
    vSelect,
  },

  computed: {
    allAgencies: function () {
      return this.$store.getters["search/getAllAgencies"];
    },
    selectedItems: function () {
      return this.$store.getters["search/getAgencyTags"];
    },
  },

  methods: {
    updateTags(value) {
      this.updateItemsInStore(value);
    },

    updateItemsInStore(value) {
      this.$store.dispatch("search/updateAgencies", value);

      // Grabs all but the last item
      const allButLast = value.slice(0, -1);
      // Builds a string for all states
      let allStatesString = "";
      for (let item of allButLast) {
        allStatesString += item.name + "|";
      }

      // Adds the last item to string without the "|" delimiter
      value.length >= 1
        ? (allStatesString += value[value.length - 1].name)
        : "";

      const encodedParams = encodeURI(`${allStatesString}`);
      addOrUpdateParameter("agencies", encodedParams);
    },
  },
};
</script>

<style>
.vs__dropdown-option--selected {
  background-color: lightgray !important;
  color: #000 !important;
}
.vs__dropdown-option--selected:hover {
  color: #000 !important;
}
</style>

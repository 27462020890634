<template>

  <div class="modal-container" id="customModal" @click="event => closeModal(event)">
    <div class="modal-wrapper">

      <div class="modal-content">
        <div class="modal-sidebar">
          <div class="mb-3 mt-2">
            <logo width="30px"/>
          </div>

          <div class="modal-sidebar-link"
               @click="() => changePage(1)"
               :class="{'modal-sidebar-link--active': determineIfActive(1)}">
            <edit3-icon/>
          </div>

          <div class="modal-sidebar-link"
               @click="() => changePage(2)"
               :class="{'modal-sidebar-link--active': determineIfActive(2)}">
            <square-icon/>
          </div>

          <div class="modal-sidebar-link"
               @click="() => changePage(3)"
               :class="{'modal-sidebar-link--active': determineIfActive(3)}">
            <calendar-icon/>
          </div>

          <div class="modal-sidebar-link" v-if="!isDisabled"
               @click="() => changePage(4)"
               :class="{'modal-sidebar-link--active': determineIfActive(4)}">
            <mail-icon/>
          </div>

        </div>

        <div class="modal-tab-wrapper">

          <div class="modal-tab-header">
            <div>
              <h5 class="text-uppercase fw-bold text-green mb-2 mb-md-0">
                Edit Saved Search
              </h5>
            </div>

            <div class="d-flex align-items-center">
              <div class="btn btn-green btn-sm me-2" @click="updateSavedSearch">Save</div>
              <div class="btn btn-ra-gray btn-sm me-2" @click="resetSearchOptions" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Clear search criteria">Clear</div>
              <button type="button" class="btn-close" aria-label="Close" @click="hideModal"></button>
            </div>
          </div>

          <div v-if="getIsLoading">
            <spinner/>
          </div>
          <div v-else class="modal-tab-content">
            <template v-if="page === 1">
              <label class="small text-dark" for="name">Save Search Name</label>
              <input type="text" id="name" class="form-control form-control-sm" placeholder="Name..." v-model="saved_search_name">
              <br>
              <h5>Keywords</h5>
              <or-includes/>
              <include/>
              <exclude/>
            </template>

            <template v-if="page === 2">
              <h5>Article Details</h5>
              <agencies/>
              <countries/>
              <states/>
              <sector-types/>
              <higher-order-alert-classification/>
              <sub-type-classification/>
              <alert-types/>
              <tags/>
              <classification/>
              <status/>
              <filing-type/>
              <in-federal-register/>
              <federal-register-number/>
              <reg-alytics-alert-id/>
            </template>

            <template v-if="page === 3">
              <h5>Dates</h5>
              <latest-update/>
              <hr>
              <originally-published/>
              <hr>
              <proposed-comments-due/>
              <hr>
              <rule-effective/>
              <hr>
              <published-in-federal-reserve/>
            </template>
<!--            state.savedItemDetails.name-->
            <template v-if="page === 4 && !isDisabled">
              <h5>Distribution</h5>
              <distribution-container :saved-search-id="savedSearch.id" />
            </template>

          </div>
        </div>
      </div>

    </div>
  </div>

<!--  <div id="customModal" class="custom-modal-container" @click="event => closeModal(event)">

    <div class="modal-content">
      <div class="modal-container">

        <div class="modal-header">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <h5 class="text-right pb-0 mb-0">Editing Saved Search</h5>
            <div class="d-flex align-items-center">
              <span class="btn btn-success btn-sm me-3" @click="updateSavedSearch">Save</span>
              <span class="search-container-reset-option me-3" @click="resetSearchOptions">Clear Criteria</span>
              <button type="button" aria-label="Close" class="btn-close" @click="hideModal"></button>
            </div>
          </div>
        </div>

        <div class="modal-content">
          <p class="text-center" v-if="getIsLoading">
            <spinner/>
          </p>
          <edit-saved-search-content v-else :saved-search="savedSearch"/>
        </div>
      </div>
    </div>

  </div>-->
</template>

<script>
import Agencies from "@/components/articles/articles-search-options/Agencies";
import AlertTypes from "@/components/articles/articles-search-options/AlertTypes";
import Classification from "@/components/articles/articles-search-options/Classification";
import Countries from "@/components/articles/articles-search-options/Countries";
import Exclude from "@/components/articles/articles-search-options/Exclude";
import FederalRegisterNumber from "@/components/articles/articles-search-options/FederalRegisterNumber";
import FilingType from "@/components/articles/articles-search-options/FilingType";
import HigherOrderAlertClassification from "@/components/articles/articles-search-options/HigherOrderAlertClassification.vue";
import Include from "@/components/articles/articles-search-options/Include";
import InFederalRegister from "@/components/articles/articles-search-options/InFederalRegister";
import LatestUpdate from "@/components/articles/articles-search-options/LatestUpdate";
import OriginallyPublished from "@/components/articles/articles-search-options/OriginallyPublished";
import OrIncludes from "@/components/articles/articles-search-options/OrIncludes";
import ProposedCommentsDue from "@/components/articles/articles-search-options/ProposedCommentsDue";
import PublishedInFederalReserve from "@/components/articles/articles-search-options/PublishedInFederalReserve";
import RegAlyticsAlertId from "@/components/articles/articles-search-options/RegAlyticsAlertId";
import RuleEffective from "@/components/articles/articles-search-options/RuleEffective";
import SectorTypes from "@/components/articles/articles-search-options/SectorTypes";
import States from "@/components/articles/articles-search-options/States";
import Status from "@/components/articles/articles-search-options/Status";
import SubTypeClassification from "@/components/articles/articles-search-options/SubTypeClassification";
import Tags from "@/components/articles/articles-search-options/Tags";
import DistributionContainer from "@/components/saved-searches-edit-modal/DistributionContainer";
import Spinner from "@/components/Spinner";
import { addOrUpdateParameter, resetUrlParams } from "@/helpers/urlParamsHelper";
import Logo from "@/layouts/components/Logo";
import { defaultSearchOptions, logSearchHistory } from "@/store/services/articleService";
import { CalendarIcon, Edit3Icon, MailIcon, SquareIcon } from 'vue-feather-icons';
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SavedSearchesEditModal",

  components: {
    SubTypeClassification,
    HigherOrderAlertClassification,
    Spinner,
    DistributionContainer,
    PublishedInFederalReserve,
    RuleEffective,
    ProposedCommentsDue,
    OriginallyPublished,
    LatestUpdate,
    RegAlyticsAlertId,
    FederalRegisterNumber,
    InFederalRegister,
    FilingType,
    Countries,
    Status,
    Classification,
    AlertTypes,
    Tags,
    States,
    Agencies,
    Exclude,
    Include,
    OrIncludes,
    Logo,
    Edit3Icon,
    SectorTypes,
    SquareIcon,
    CalendarIcon,
    MailIcon
  },

  data() {
    return {
      savedSearch: {},
      page: 1,
    }
  },

  computed: {
    ...mapGetters('editSavedSearch', ['getIsLoading']),
    ...mapGetters('search', ['getShowingModal', 'getSearchValue']),
    ...mapGetters('accounts', ['getCurrentUser']),
    saved_search_name: {
      get () {
        return this.$store.getters["editSavedSearch/getSavedSearchName"];
      },
      set (value) {
        this.$store.commit('editSavedSearch/SET_SAVED_SEARCH_NAME', value)
      }
    },
    isDisabled() {
      return this.getCurrentUser.distribution === false;
    }
  },

  methods: {
    ...mapGetters('editSavedSearch', ['getSavedItemDetails']),
    ...mapActions('editSavedSearch', ['updateSavedSearch']),
    ...mapActions('search', ['getAllOptionsForAdvancedSearch']),


    // Start of old methods
    getSavedSearchDetails() {
      this.savedSearch = {...this.getSavedItemDetails()};
    },

    // End of old methods

    closeModal(event) {
      if (event.target.id === 'customModal') {
        this.$store.dispatch('editSavedSearch/setShowingModal', false);
      }
    },

    changePage(pageNumber) {
      this.page = pageNumber;
    },

    // Determines if the current page is active based on the number provided
    determineIfActive(pageNumber) {
      return pageNumber === this.page;
    },

    // Resets the search options to the default state
    resetSearchOptions() {
      const parsedString = JSON.stringify(defaultSearchOptions);
      const clone = JSON.parse(parsedString);
      this.$store.dispatch('search/resetSearchFilters', clone);
      this.$store.dispatch("collections/setCollectionItem", null);

      // Resets the URL to the default route
      resetUrlParams();
    },

    hideModal() {
      this.$store.dispatch('editSavedSearch/setShowingModal', false);
    },

    updateSearchNavigation(value) {
      this.$store.dispatch('search/updateSearchValue', value);
      // Adds the new value as parameters to the URI
      const encodedParams = encodeURI(value);
      addOrUpdateParameter("searchValue", encodedParams);
    },

    searchArticles() {
      this.hideModal();
      this.$store.dispatch('articles/setLoadingFromExternalSource', true);
      this.$store.dispatch('search/getSearchResults')
          .then(response => {
            // Builds the response in the format we require for the front-end
            // Required when using the materialized view
            let responseData = response.data.results;
            responseData.forEach((item, index) => {

              let alertType = ''

              if (item.alert_type) {
                alertType = item.alert_type;
              }

              responseData[index].alert_type = alertType;
            });
            this.$store.dispatch('articles/setArticlesFromExternalSource', responseData);
            this.$store.dispatch('articles/generateAndSetFilterOptions');
            this.$store.dispatch('articles/setLoadingFromExternalSource', false);
          })
          .then(() => {
            logSearchHistory();
          });
    },
    updateName(v){
      console.log(v)
    }

  },


  created() {
    this.getSavedSearchDetails();
  }

}
</script>

<style lang="scss" scoped>
.modal-container {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.modal-wrapper {
  position: relative;
  border: none;
  border-radius: 0;
  outline: 0;
  background: white;
  margin: 0 0;
  width: 100% !important;
  height: 100%;

  @media (min-width: 991px) {
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 10px;
    margin: 3% auto; /* 15% from the top and centered */
    width: 80%;
    max-width: 900px;
    height: fit-content;
  }


  /* Animate the content of the modal */
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.5s;
  animation-name: animatetop;
  animation-duration: 0.5s
}

.modal-content {
  display: flex;
  flex-direction: row;
  border-radius: 10px;

}

.modal-sidebar {
  border-radius: 10px 0 0 10px;
  width: 80px;
  min-height: 100vh;
  background: white;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08) !important;

  @media (min-width: 991px) {
    min-height: 500px;
  }

  justify-content: center;
  text-align: center;

  padding: 10px 0;

}

.modal-sidebar-link {
  padding: 14px 5px;

  cursor: pointer;
  transition: 0.25s;

  &:hover {
    background-color: #eaeaea;
  }
}

.modal-sidebar-link--active {
  border-left: 2px solid #4a3288;
  background-color: #f8f8f8;
}

.modal-tab-wrapper {
  width: 100%;
  padding: 15px 20px;
}

.modal-tab-content {
  margin-top: 15px;
}

.modal-tab-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background-color: white;
  //border: gainsboro 1px solid;

  @media(min-width: 768px) {
    flex-direction: row;
  }
}

.custom-searchbar {
  width: 100%;
  border: 0;
  padding: .4rem 0;
  font-size: 1.5rem;

  &:focus {
    border: none;
    outline: none;
  }
}

@-webkit-keyframes animatetop {
  from {top:-10px; opacity:0}
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-10px; opacity:0}
  to {top:0; opacity:1}
}
</style>

<template>
  <div class="my-3">
    <p class="mb-0 font-weight-bold">Alert Subclassification</p>
    <v-select
      multiple
      label="name"
      :options="allSubTypeClassification"
      :value="selectedItems"
      @input="updateTags"
      :select-on-tab="true"
      :close-on-select="false"
      :clearSearchOnSelect="false"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import { addOrUpdateParameter } from "@/helpers/urlParamsHelper";

export default {
  name: "SubTypeClassification",

  components: {
    vSelect,
  },

  computed: {
    allSubTypeClassification: function () {
      return this.$store.getters["search/getAllSubTypeClassification"];
    },
    selectedItems: function () {
      return this.$store.getters["search/getSubTypeClassification"];
    },
  },

  methods: {
    updateTags(value) {
      this.updateItemsInStore(value);
    },

    updateItemsInStore(value) {
      console.log(value);
      this.$store.dispatch("search/updateSubTypeClassification", value);

      // Grabs all but the last item
      const allButLast = value.slice(0, -1);
      // Builds a string for all states
      let allStatesString = "";
      for (let item of allButLast) {
        allStatesString += item.name + "|";
      }

      // Adds the last item to string without the "|" delimiter
      value.length >= 1
        ? (allStatesString += value[value.length - 1].name)
        : "";

      const encodedParams = encodeURI(`${allStatesString}`);
      addOrUpdateParameter("subtype_classification", encodedParams);
    },
  },
};
</script>

<template>
  <div class="articles-pagination">


    <div v-if="getCurrentPage < getAllPages">
      <div class="text-center mt-4" v-if="getFetchingNewPage">
        <spinner/>
      </div>

      <div @click="loadMore" class="load-more" v-else>
        Load more
      </div>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import Spinner from "@/components/Spinner";

export default {
  name: "ArticlesPagination",
  components: {Spinner},
  computed: {
    ...mapGetters('articles', ['getCurrentPage', 'getAllPages', 'getFetchingNewPage'])
  },

  data() {
    return {
      showingPagination: false
    }
  },

  methods: {
    ...mapActions('articles', ['searchAdditionalArticles', 'updateCurrentPage', 'setFetchingNewPage']),

    loadMore() {
      this.setFetchingNewPage(true);
      const newPage = this.getCurrentPage + 1;
      this.searchAdditionalArticles(newPage);
      this.updateCurrentPage(newPage);
    }

  }

}
</script>

<style lang="scss" scoped>
.articles-pagination {
  margin-top: 30px;

  .load-more {
    width: 30%;
    min-width: 200px;
    margin: 0 auto;
    text-align: center;
    padding: .5rem 1rem;
    cursor: pointer;
    font-weight: bold;
    border: 1px solid #dedede;
    color: #dedede;;

    border-radius: 6px;
    transition: .25s;

    &:hover {
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
      border: 1px solid #393939;
      color: #393939;;
    }
  }
}
</style>
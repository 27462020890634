<template>
  <div class="icon-styling" @mouseover="showingPopover = true"
       @mouseleave="showingPopover = false">
    <div v-show="showingArticlesOptions" class="dropdown-overlay" @click="showingArticlesOptions = false"></div>

    <save-icon @click="showingArticlesOptions = !showingArticlesOptions"/>

    <div v-show="showingArticlesOptions" class="articles-dropdown-content">
      <div class="layout-options">
        <add-saved-search/>
      </div>
    </div>

    <custom-pop-over
        :left="-200"
        v-if="showingPopover">
      Save Search
    </custom-pop-over>
  </div>
</template>

<script>
import CustomPopOver from "@/components/CustomPopOver";
import {SaveIcon} from "vue-feather-icons";
import AddSavedSearch from "@/components/add-saved-search/AddSavedSearch";

export default {
  name: "SaveSearch",
  components: {
    CustomPopOver,
    AddSavedSearch,
    SaveIcon
  },

  data() {
    return {
      showingArticlesOptions: false,
      showingPopover: false
    }
  }

}
</script>

<style lang="scss" scoped>
.articles-dropdown-btn {
  background-color: white;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    fill: #545454;
  }
}

.download-report {
  font-size: 0.8rem;
  color: #4a3288;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: #c3c3c3;
  }
}

.articles-dropdown {
  float: right;
  position: relative;
  display: inline-block;
}

.articles-dropdown-content {
  margin-top: 20px;
  position: absolute;
  min-width: 350px;
  padding: 10px 20px;
  z-index: 999;
  display: block;
  right: 0;
  background-color: white;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #e7e7e7;
  border-radius: 4px;
}

.dropdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}
</style>

<template>
  <div class="icon-styling" @click="() => updateModalShowingState(true)"  @mouseover="showingPopover = true"
       @mouseleave="showingPopover = false">
    <search-icon/>
    <custom-pop-over
        :left="-200"
        v-if="showingPopover">
      Search
    </custom-pop-over>
  </div>
</template>

<script>
import CustomPopOver from "@/components/CustomPopOver";
import {SearchIcon} from 'vue-feather-icons';
import {mapActions} from "vuex";

export default {
  name: "AdvancedSearch",
  components: {
    CustomPopOver,
    SearchIcon
  },

  data() {
    return {
      showingPopover: false
    }
  },

  methods: {
    ...mapActions('search', ['updateModalShowingState']),
  }

}
</script>

<style scoped>

</style>

<template>
  <div class="my-3">
    <p class="mb-0 font-weight-bold">Federal Register Number</p>
    <input :value="statusDetails" class="form-control form-control-sm" type="text"
           @change="(event) => updateStatus(event.target.value)">
  </div>
</template>

<script>
import {addOrUpdateParameter} from "@/helpers/urlParamsHelper";


export default {
  name: "FederalRegisterNumber",

  computed: {
    statusDetails: function () {
      return this.$store.getters["search/getFederalRegisterNumber"];
    }
  },

  methods: {
    updateStatus(value) {
      this.$store.dispatch('search/updateFederalRegisterNumber', value);

      // Adds the new value as parameters to the URI
      const encodedParams = encodeURI(value);
      addOrUpdateParameter("federalRegisterNumber", encodedParams);
    }
  }
}
</script>

<template>
  <div class="my-3">
    <p class="mb-0 font-weight-bold">In Federal Register</p>
    <div class="form-check form-check-inline">
      <input type="checkbox" class="form-check-input" v-model="statusDetails.yes" @change="updateStatus">
      <label>Yes</label>
    </div>
    <div class="form-check form-check-inline">
      <input type="checkbox" class="form-check-input" v-model="statusDetails.not_yet" @change="updateStatus">
      <label>Not Yet</label>
    </div>
    <div class="form-check form-check-inline">
      <input type="checkbox" class="form-check-input" v-model="statusDetails.not_expected" @change="updateStatus">
      <label>Not Expected</label>
    </div>
  </div>
</template>

<script>
import {addOrUpdateParameter} from "@/helpers/urlParamsHelper";

export default {
  name: "InFederalRegister",

  computed: {
    statusDetails: function() {
      return this.$store.getters["search/getFederalRegister"];
    },

    showingYes: function() {
      let isShowing = true;
      if (this.statusDetails.not_yet) isShowing = false;
      if (this.statusDetails.not_expected) isShowing = false;
      return isShowing;
    },

    showingNotYet: function() {
      let isShowing = true;
      if (this.statusDetails.yes) isShowing = false;
      if (this.statusDetails.not_expected) isShowing = false;
      return isShowing;
    },

    showingNotExpected: function() {
      let isShowing = true;
      if (this.statusDetails.yes) isShowing = false;
      if (this.statusDetails.not_yet) isShowing = false;
      return isShowing;
    },

  },

  methods: {
    updateStatus() {
      let temp_state = {...this.statusDetails};
      this.$store.dispatch('search/updateInFederalRegisterValue', {
        yes: temp_state.yes,
        not_yet: temp_state.not_yet,
        not_expected: temp_state.not_expected
      });

      // Adds the selected options as parameters to the URI
      const encodedParams = encodeURI(`${temp_state.yes}|${temp_state.not_yet}|${temp_state.not_expected}`);
      addOrUpdateParameter("inFederalRegister", encodedParams);
    }
  }

}
</script>
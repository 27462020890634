<template>
  <div class="container">

    <article-modal v-if="getIsShowingModal" />
    <article-advanced-search-modal v-if="getShowingModal" />
    <saved-searches-modal v-if="isShowingCollectionsModal" />
    <saved-searches-edit-modal v-if="isShowingEditSavedSearchModal" />
    <folder-create-modal v-if="isShowingCreateFolderModal" />
    <release-notes-modal v-if="getReleaseModal" />
    <articles-header />


    <articles-table />

  </div>
</template>

<script>
import ArticlesTable from "@/components/articles/articles-table/ArticlesTable";
import ArticlesHeader from "@/components/articles/articles-header/ArticlesHeader";
import { getAllUrlParamsOnLoad, getSavedSearchName, getSearchValue } from "@/helpers/urlParamsHelper";
import ArticleModal from "@/components/articles/article-modal/ArticleModal";
import { mapActions, mapGetters } from "vuex";
import ArticleAdvancedSearchModal from "@/components/articles/articles-advanced-search/ArticleAdvancedSearchModal";
import SavedSearchesModal from "@/components/saved-searches-modal/SavedSearchesModal";
import SavedSearchesEditModal from "@/components/saved-searches-edit-modal/SavedSearchesEditModal";
import FolderCreateModal from "@/components/folders/folder-create-modal/FolderCreateModal";
import ReleaseNotesModal from "@/components/release_notes/ReleaseNotesModal";

export default {
  name: "Articles",
  components: {
    FolderCreateModal,
    ReleaseNotesModal,
    SavedSearchesEditModal,
    SavedSearchesModal,
    ArticleAdvancedSearchModal,
    ArticleModal,
    ArticlesHeader,
    ArticlesTable,
  },

  computed: {
    ...mapGetters('articles', ['getIsShowingModal', 'getIsFetchingArticles']),
    ...mapGetters('search', ['getShowingModal', 'getReleaseModal']),
    ...mapGetters('accounts', ['getCurrentUser']),
    ...mapGetters('folders', ['getFolderModalOpen']),

    isShowingCollectionsModal() {
      return this.$store.getters['collections/getIsShowingModal'];
    },

    isShowingEditSavedSearchModal() {
      return this.$store.getters['editSavedSearch/getIsShowingModal'];
    },

    isShowingCreateFolderModal() {
      return this.getFolderModalOpen;
    }

  },

  methods: {
    ...mapActions('articles', ['setIsFetchingArticles']),
    ...mapActions('folders', ['fetchAllFolders']),

    fetchArticles() {
      // Fetch the current URL details
      const url = new URL(window.location.href);

      // If there is possible search parameters swap to filtering for articles
      if (url.search.trim().length >= 2) {
        // Find all parameters and update the state
        const searchValues = getAllUrlParamsOnLoad();
        const searchValue = getSearchValue();
        this.$store.dispatch('search/updateAllSearchOptions', searchValues);
        this.$store.dispatch('search/updateSearchValue', searchValue);

        // Fetch all articles based on search options
        this.$store.dispatch('articles/searchArticles');
      } else {
        // check for a pinned saved search and return it if found
        this.$store.dispatch('collections/checkForPinnedArticleRedirect');
      }
    }

  },

  mounted() {
    this.setIsFetchingArticles(true);
    // Fetches all URL parameters and loads them into state
    const searchValues = getAllUrlParamsOnLoad();
    const searchValue = getSearchValue();
    const savedSearchName = getSavedSearchName();
    this.$store.dispatch('search/updateAllSearchOptions', searchValues);
    this.$store.dispatch('search/updateSearchValue', searchValue);
    this.$store.dispatch('search/setSavedSearchName', savedSearchName);
    this.$store.dispatch('filters/resetAllSelectedFiltersSidebar');

    this.fetchArticles();
    this.fetchAllFolders();
    const release_note = this.getCurrentUser.show_release_note
    if (release_note) {
      this.$store.dispatch('search/updateModalReleaseState', true);
    }
  }

}
</script>

<template>
  <div class="my-3">
    <div class="d-flex justify-content-between align-items-center">
      <p class="mb-0 font-weight-bold">Excludes (any of these words):</p>
      <pop-over icon="icon">
        Exclude any Articles that contain any keywords in either the title, summary or agencies.
        Example: bank, money (Ignores any Articles that contain either "bank" and/or "money")
      </pop-over>
    </div>

    <v-select
        multiple
        taggable
        placeholder="Enter keyword and press enter..."
        :noDrop="true"
        :value="allExcludeKeywords"
        @input="updateTags"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select';
import {addOrUpdateParameter} from "@/helpers/urlParamsHelper";
import PopOver from "@/components/articles/articles-search-options/PopOver";


export default {
  name: "Exclude",

  components: {
    PopOver,
    vSelect
  },

  computed: {
    allExcludeKeywords: function () {
      return this.$store.getters["search/getAllExcludesKeywords"];
    }
  },

  methods: {

    updateTags(value) {
      this.$store.dispatch('search/setExcludeKeywords', value);

      // Grabs all but the last item
      const allButLast = value.slice(0, -1);
      // Builds a string for all excludes keywords
      let allItemsString = "";
      for (let item of allButLast) {
        allItemsString += item + "|";
      }

      // Adds the last item to string without the "|" delimiter
      value.length >= 1 ? allItemsString += value[value.length - 1] : "";

      const encodedParams = encodeURI(`${allItemsString}`)
      addOrUpdateParameter("excludes", encodedParams);
    }

  }

}
</script>

<template>
  <div>

    <div v-if="isLoading" class="text-center">
      <spinner/>
    </div>
    <div v-else>

      <div v-if="hasAnEndDateSelected">
        <span class="text-danger">You cannot have a distribution with an ending date!</span>
      </div>
      <div v-else>
        <div class="d-flex justify-content-between">
          <div>
            <p v-if="message.displayed" :class="`text-${message.type}`">{{ message.msg }}</p>
          </div>
          <p class="delete-icon" v-if="hasDistribution" @click="removeSavedSearchDistribution">
            <trash2-icon size="16"/>
          </p>
        </div>
        <form @submit.prevent="updateDistributionSettings">
          <div class="mb-3">
            <select class="form-control" name="distributionOptions" id="distributionOptions" v-model="selectedDistribution">
              <option value="" disabled>Please select an option</option>
              <option value="Daily">Daily</option>
              <option value="Weekly">Weekly</option>
              <option value="Monthly">Monthly</option>
            </select>
          </div>

          <div v-if="selectedDistribution.length > 1">
            <div class="mb-3">
              <label for="daySelector">Select Day to Start Receiving Reports</label>
              <input class="form-control" type="date" id="daySelector" v-model="startingDate" required>
            </div>
            <button class="btn btn-dark">
              <span v-if="hasDistribution">Update Distribution Setting</span>
              <span v-else>Save Distribution Setting</span>
            </button>
          </div>
        </form>
      </div>

    </div>

  </div>
</template>


<script>
import axios from "@/axios";
import Spinner from "@/components/Spinner";
import {Trash2Icon} from 'vue-feather-icons';

export default {
  name: "DistributionContainer",
  components: {Spinner,Trash2Icon},

  data() {
    return {
      selectedDistribution: '',
      startingDate: '',
      isLoading: false,
      message: {
        displayed: false,
        type: '',
        msg: ''
      },
      hasDistribution: false
    }
  },

  computed: {

    lastUpdatedEnd: function() {
      return this.$store.getters["search/getLatestUpdate"].end;
    },

    originallyPublishedEnd() {
      return this.$store.getters["search/getOriginallyPublished"].end;
    },

    proposedCommentsDueEnd() {
      return this.$store.getters["search/getProposedCommentsDue"].end;
    },

    ruleEffectiveDate() {
      return this.$store.getters["search/getRuleEffective"].end;
    },

    publishedInFederalReserveEnd() {
      return this.$store.getters["search/getPublishedInFederalReserve"].end;
    },

    // Determine if the user has selected an option with an ending date
    hasAnEndDateSelected() {
      if (this.lastUpdatedEnd && this.lastUpdatedEnd.length >= 1) return true;
      if (this.originallyPublishedEnd && this.originallyPublishedEnd.length >= 1) return true;
      if (this.proposedCommentsDueEnd && this.proposedCommentsDueEnd.length >= 1) return true;
      if (this.ruleEffectiveDate && this.ruleEffectiveDate.length >= 1) return true;
      if (this.publishedInFederalReserveEnd && this.publishedInFederalReserveEnd.length >= 1) return true;
      return false;
    }

  },

  props: {
    savedSearchId: Number
  },

  methods: {

    clearMessage() {
      this.message = {
        displayed: false,
        msg: '',
        type: ''
      }
    },

    updateDistributionSettings() {
      this.clearMessage();
      this.isLoading = true;
      let newDate = new Date(this.startingDate).toUTCString();
      axios.post(`/collections/distribution/${this.savedSearchId}`, {
        distribution_type: this.selectedDistribution,
        date: newDate
      }, { withCredentials: true })
          .then(response => {
            this.message = {
              displayed: true,
              msg: response.data,
              type: 'green'
            }

            if (this.hasDistribution) {
              this.$tracking.trackEvent('updatedDistribution', {
                savedSearchId: this.savedSearchId,
                selectedDistribution: this.selectedDistribution,
                startingDate: this.startingDate
              });
            } else {
              this.$tracking.trackEvent('createdDistribution', {
                savedSearchId: this.savedSearchId,
                selectedDistribution: this.selectedDistribution,
                startingDate: this.startingDate
              });
            }

            this.hasDistribution = true;
            this.isLoading = false;
            setTimeout(() => {
              this.clearMessage();
            }, 3000);
          }).catch(err => {
        this.message = {
          displayed: true,
          msg: err.response.data,
          type: 'danger'
        }
        this.isLoading = false;
        setTimeout(() => {
          this.clearMessage();
        }, 3000);
      })
    },

    // Fetches the date, and selected distribution for the current saved search
    // Will pre-populate if the data exists
    fetchDistributionSettings() {
      this.clearMessage();
      this.isLoading = true;
      axios.get(`/collections/distribution/${this.savedSearchId}`, {
        withCredentials: true
      }).then(response => {
        const newDate = new Date(response.data.date);
        this.startingDate = newDate.toISOString().substring(0, 10);
        this.selectedDistribution = response.data.distribution_type;
        this.hasDistribution = true;
        this.isLoading = false;
      })
          .catch(() => {
            this.isLoading = false;
          })
    },

    removeSavedSearchDistribution() {
      this.clearMessage();
      this.isLoading = true;
      axios.delete(`/collections/distribution/${this.savedSearchId}`, {withCredentials: true})
          .then(response => {

            this.message = {
              displayed: true,
              msg: response.data,
              type: 'green'
            }

            this.isLoading = false;
            setTimeout(() => {
              this.clearMessage();
            }, 3000);

            this.$tracking.trackEvent('removedDistribution', {
              savedSearchId: this.savedSearchId
            });

            this.selectedDistribution = '';
            this.startingDate = '';
            this.hasDistribution = false;
          })
          .catch(err => {
            this.message = {
              displayed: true,
              msg: err.response.data,
              type: 'danger'
            }
            this.isLoading = false;
            setTimeout(() => {
              this.clearMessage();
            }, 3000);
          })
    }

  },

  created() {
    this.fetchDistributionSettings();
  }

}
</script>

<style lang="scss" scoped>
.delete-icon {
  svg {
    color: #cd6262;
    cursor: pointer;

    &:hover {
      color: #882626;
    }
  }
}
</style>

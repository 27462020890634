
import Vue from "vue";
import CustomPopOver from "@/components/CustomPopOver.vue";
import {GridIcon} from "vue-feather-icons";
import {mapGetters} from "vuex";
import {ArticlesLayout} from "@/types/account/User";
import axios from "@/axios";


export default Vue.extend({
  name: "ChangeLayout",
  components: {CustomPopOver, GridIcon},

  data() {
    return {
      showingPopover: false,
      articlesLayout: ArticlesLayout
    }
  },

  computed: {
    ...mapGetters('accounts', ['getCurrentUser']),
  },

  methods: {

    changeLayout(layout: ArticlesLayout) {
      this.$store.dispatch('articles/setFetchingArticles', true);

      axios.post('/articles/change-layout', {
        articles_layout: layout
      }, { withCredentials: true })
      .then(() => {
        this.$store.dispatch('accounts/setUserLayout', layout);
        this.$store.dispatch('articles/setFetchingArticles', false);
      })
      .catch(() => {
        this.$store.dispatch('notifications/addNotification', {
          message: 'There was an issue updating the layout. Please try again!',
          type: 'danger'
        });
        this.$store.dispatch('articles/setFetchingArticles', false);
      });

    }

  }
});

<template>
  <div class="my-3">
    <p class="mb-0 font-weight-bold">Filing Type</p>
    <div class="form-check form-check-inline">
      <input type="checkbox" class="form-check-input" v-model="statusDetails.single" @change="updateStatus">
      <label>Single</label>
    </div>
    <div class="form-check form-check-inline">
      <input type="checkbox" class="form-check-input" v-model="statusDetails.joint" @change="updateStatus">
      <label>Joint</label>
    </div>
  </div>
</template>

<script>
import {addOrUpdateParameter} from "@/helpers/urlParamsHelper";

export default {
  name: "FilingType",

  computed: {
    statusDetails: function() {
      return this.$store.getters["search/getFilingType"];
    }
  },

  methods: {
    updateStatus() {
      let temp_state = {...this.statusDetails};
      this.$store.dispatch('search/updateFilingTypeValues', {
        single: temp_state.single,
        joint: temp_state.joint
      });

      // Adds the selected options as parameters to the URI
      const encodedParams = encodeURI(`${temp_state.single}|${temp_state.joint}`);
      addOrUpdateParameter("filingType", encodedParams);
    }
  }

}
</script>
<template>
  <div class="article-card-table overflow-auto table-custom-padding">
    <table class="table table-hover">
      <thead>
      <tr>
        <th>Date</th>
        <th>Agency</th>
        <th>Title</th>
        <th>Add</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="article in allArticles" class="article-table-row" :key="article.id" :title="`Title: ${article.title} - Summary: ${article.summary}`">
        <td style="min-width: 180px;"  @click="() => loadArticle(article)">
          <span>
              {{ new Date(article.latest_update).toLocaleString('default', {month: 'long', timeZone: 'UTC'}) }}
              {{ article.latest_update.split('-')[2] }}, {{ new Date(article.latest_update).getUTCFullYear() }}
            </span>
        </td>
        <td  @click="() => loadArticle(article)">
          <div style="max-height: 24px; overflow-y: hidden;">
            {{ createAgenciesString(article.agencies) }}
          </div>
        </td>
        <td  @click="() => loadArticle(article)">
          <div style="max-height: 24px; overflow-y: hidden;">
            <span v-if="article.title.length > 40">{{ shortenTitle(article.title) }}...</span>
            <span v-else>{{ article.title }}</span>
          </div>
        </td>
        <td>
          <article-card-save :article="article" />
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {createAgenciesString, truncateString} from "@/helpers/stringHelpers";
import ArticleCardSave from "@/components/articles/articles-card/article-card-save/ArticleCardSave";
import {logViewedArticle} from "@/store/services/articleService";

export default {
  name: "ArticleCardTable",
  components: {ArticleCardSave},
  props: {
    allArticles: Array
  },

  methods: {

    shortenTitle(title) {
      return truncateString(title, 40);
    },

    shortenSummary(summary) {
      return truncateString(summary, 280);
    },

    createAgenciesString(allAgencies) {
      return createAgenciesString(allAgencies);
    },

    // Fire an event to display the Articles modal
    showArticleModal(article) {
      this.$store.dispatch('articles/updateModalContent', article);
      this.$store.dispatch('articles/updateModalShowingState', true);
    },

    // Used to open the modal and fire an event to log telemetry
    loadArticle(article) {
      logViewedArticle(article.id);
      this.showArticleModal(article);
      this.$tracking.trackEvent('ViewedArticle', {
        id: article.id,
        regalytics_alert_id: article.regalytics_alert_id,
        title: article.title,
        agencies: article.agencies,
        alert_type: article.alert_type,
        classification: article.classification,
        federal_register_number: article.federal_register_number,
        federal_register_publication_date: article.federal_register_publication_date,
        filing_type: article.filing_type,
        in_federal_register: article.in_federal_register,
        states: article.states,
        status: article.status
      });
    },

  }

}
</script>

<style scoped lang="scss">
.article-card-table {
  background-color: white;
  box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: .2rem 1rem;
}

.table-custom-padding {
  padding-bottom: 120px;
}

.article-table-row {
  cursor: pointer;
}
</style>

<template>
  <div class="my-3">
    <div class="d-flex justify-content-between align-items-center">
      <p class="mb-0 font-weight-bold">Country</p>
      <PopOver icon="icon"> Not Present if not permitted </PopOver>
    </div>
    <v-select
      multiple
      label="name"
      :options="allCountries"
      :value="selectedItems"
      @input="updateTags"
      :select-on-tab="true"
      :close-on-select="false"
      :clearSearchOnSelect="false"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import { addOrUpdateParameter } from "@/helpers/urlParamsHelper";
import PopOver from "@/components/articles/articles-search-options/PopOver";

export default {
  name: "Countries",

  components: {
    vSelect,
    PopOver,
  },

  computed: {
    allCountries: function () {
      return this.$store.getters["search/getAllCountries"];
    },

    selectedItems: function () {
      return this.$store.getters["search/getCountryTags"];
    },
  },

  methods: {
    updateTags(value) {
      this.updateItemsInStore(value);
    },

    updateItemsInStore(value) {
      this.$store.dispatch("search/updateCountries", value);

      // Grabs all but the last item
      const allButLast = value.slice(0, -1);
      // Builds a string for all states
      let allCountriesString = "";
      for (let item of allButLast) {
        allCountriesString += item.name + "|";
      }

      // Adds the last item to string without the "|" delimiter
      value.length >= 1
        ? (allCountriesString += value[value.length - 1].name)
        : "";

      const encodedParams = encodeURI(`${allCountriesString}`);
      addOrUpdateParameter("countries", encodedParams);
    },
  },
};
</script>

<template>
  <div class="modal-container" id="customModal" @click="event => closeModal(event)">

    <div class="modal-content">
      <!-- Saved Search Modal Header -->
      <div class="modal-header modal-header-custom">

        <ul class="nav nav-tabs ul-custom" id="pills-tab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="pills-home-tab" data-toggle="pill" @click="openSearchArea($event, 'Saved')"
               role="tab" aria-controls="pills-home" aria-selected="true">
             <span style="color: #4a3288">
                <search-icon class="fa-srch"/>
             </span>
              My Saved Searches </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="pills-profile-tab" data-toggle="pill" @click="openSearchArea($event, 'Curated')"
               role="tab" aria-controls="pills-profile" aria-selected="false">
<!--              <heart-icon class="fa-srch"/>-->
              <search-icon class="fa-srch" style="display: none"/>
              <img src="@/assets/images/icon.svg" alt="RegAlytics Logo" width="21" >
              Popular Searches</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="pills-company-tab" data-toggle="pill" @click="openSearchArea($event, 'Company')"
               role="tab" aria-controls="pills-company" aria-selected="false">
              <span style="color: #4a3288">
                <users-icon class="fa-srch"/>
             </span>
              Shared Searches</a>
          </li>

        </ul>


        <div>
          <button aria-label="Close" class="btn-close" type="button" @click="hideModal"></button>
        </div>
      </div>

      <div id="Saved" class="tabcontent active">
        <all-saved-searches/>
      </div>

      <div id="Curated" class="tabcontent">
        <all-curated-searches/>
      </div>
      <div id="Company" class="tabcontent">
        <all-company-searches/>
      </div>

    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import AllSavedSearches from "@/components/saved-searches-modal/AllSavedSearches";
import AllCuratedSearches from "@/components/saved-searches-modal/AllCuratedSearches";
import AllCompanySearches from "@/components/saved-searches-modal/AllCompanySearches";
import { SearchIcon, UsersIcon} from "vue-feather-icons";

export default {
  name: "SavedSearchesModal",
  components: {AllSavedSearches, AllCuratedSearches, AllCompanySearches, SearchIcon, UsersIcon},
  computed: {
    ...mapGetters('collections', ['returnIsLoading']),
  },

  methods: {
    closeModal(event) {
      if (event.target.id === 'customModal') {
        this.$store.dispatch('collections/setShowingModal', false);
      }
    },

    hideModal() {
      this.$store.dispatch('collections/setShowingModal', false);
    },
    openSearchArea(evt, searchName) {
      var i, tabcontent, tablinks;
      tabcontent = document.getElementsByClassName("tabcontent");
      for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
      }

      tablinks = document.getElementsByClassName("nav-link");
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "");
      }

      document.getElementById(searchName).style.display = "block";
      console.log(evt)
      evt.target.className += " active"
    }
  },

  mounted() {
    this.$store.dispatch('collections/fetchAllUserCollections');
    this.$store.dispatch('collections/fetchAllCuratedCollections');
    this.$store.dispatch('collections/fetchAllCompanyCollections');
    // document.getElementById("defaultOpen").click();
  }

}
</script>

<style lang="scss" scoped>
.fa-srch {
  font-size: 10px;
  padding-right: 5px;
  position: relative;
  top: -2px
}
a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}
.ul-custom {
  //margin-top: 5px !important;
  //margin-bottom: 5px;
  margin-left: -16px;
  width: 100%;
}
.modal-header-custom {
  padding: 0rem 1rem
}
.tabcontent.active {
  display: block;
}
.custom-tab {
  margin-bottom: 0px;
  border-radius: 5px;
}
.custom-tab .nav-link {
  border: 1px solid #79974b;
  margin-right: 5px;
}
.tablinks {
  border-top: 1px solid #79974b !important;
  border-left: 1px solid #79974b !important;
  border-right: 1px solid #79974b !important;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: #79974b !important;
  color: #fff;
}
.nav-pills .nav-link {
  color: #79974b;
}
.tab button:hover {
  background-color: #79974b !important;
  color: #fff;
}
.modal-container {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
}
.modal-header {
  position: relative;
  top: 0;
  right: 0;
  padding-bottom: 1px;
  //border-bottom: 3px solid #4a3288;
  border: none !important;
  align-items: center;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  border-color: #4a3288 #4a3288 #ffffff !important;
  border-width: 1px;
  @media (max-width: 600px) {
    border: 5px solid green;
  }
}
.nav-item{
  @media (max-width: 600px) {
    width: 100%;
    height: fit-content;
  }
}
.modal-content {
  position: relative;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0;
  background: white;
  margin: 0 0;
  width: 100% !important;
  height: 100%;
  @media (min-width: 991px) {
    margin: 3% auto;
    /* 15% from the top and centered */
    width: 80%;
    max-width: 900px;
    height: fit-content;
  }
  /* Animate the content of the modal */
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.5s;
  animation-name: animatetop;
  animation-duration: 0.5s
}
@-webkit-keyframes animatetop {
  from {
    top: -10px;
    opacity: 0
  }
  to {
    top: 0;
    opacity: 1
  }
}
@keyframes animatetop {
  from {
    top: -10px;
    opacity: 0
  }
  to {
    top: 0;
    opacity: 1
  }
}
/* Style the tab */
/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: inherit;
  border: 1px solid #ccc;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.2s;
}
/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}
/* Create an active/current tablink class */
// .tab button.active {
//   background-color: #ccc;
// }
/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
.tabcontent {
  animation: fadeEffect 1s;
  /* Fading effect takes 1 second */
}
/* Go from zero to full opacity */
@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.nav-tabs
{
  border-color:#c7e18e;
}
.nav-tabs > li a {
  border: 1px solid #c7e18e;
  background-color: #ffffff;
  color:#000;
}
.nav-tabs > li > a:hover{
  background-color: #fff !important;
  border-radius: 7px;
  color:#000;
  cursor:pointer;
}
.tab-pane {
  border:solid 12px #c7e18e;
  border-top: 0;
  background-color:#D6E6F3;
  padding:5px;
}
</style>
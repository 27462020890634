<template>
  <div class="item-row">
    <div>
      <div v-if="showingDeletion" class="deletion-content">
        <span class="me-3"
        >Delete "{{ truncateSavedSearch(collectionItem.name) }}"?</span
        >
        <span>
          <span class="confirm-delete" @click="() => removeItem(collectionItem)"
          >Yes</span
          >
          <span class="confirm-delete" @click="showingDeletion = false"
          >No</span
          >
        </span>
      </div>
      <div
          v-else
          class="collection-link"
          @click="
          () =>
            loadCollection(
              collectionItem.id,
              collectionItem.parameters,
              collectionItem.name,
              collectionItem.count,
              collectionItem
            )
        "
      >
        <span class="fs-6">{{ collectionItem.name }}</span>
        <span v-if="collectionItem.modified != null" class="items-subtitle">
          Last viewed
          {{ new Date(collectionItem.modified).toDateString() }}</span
        >
        <span v-else class="items-subtitle"
        >Created on
          {{ new Date(collectionItem.created).toDateString() }}</span
        >
      </div>
    </div>
    <div class="item-right">
      <span class="text-regalytics-green small me-1">
        <!-- Start check if Article count has been checked before -->
        <template v-if="collectionItem.last_count > 0">
          <template v-if="collectionItem.count - collectionItem.last_count > 0">
            <span
                data-placement="top"
                data-toggle="tooltip"
                title="Total new Articles since last checked"
                style="cursor: pointer;
                border: 1px dotted;
                padding: 2px;"
                @click="
                loadNewCollection(
                  collectionItem.id,
                  collectionItem.parameters,
                  collectionItem.name,
                  collectionItem.count,
                  collectionItem.last_count,
                  collectionItem
                )
              "
            >
              +{{
                (
                    parseInt(
                        collectionItem.count.toString().split(",").join("")
                    ) - collectionItem.last_count
                ).toLocaleString()
              }}
            </span>
          </template>
        </template>
        <!-- End check of counts for Article -->
      </span>

      <span
          class="badge badge-pill text-dark fw-light"
          data-placement="top"
          data-toggle="tooltip"
          title="Total number of Articles in saved search"
      >
        {{ collectionItem.count.toLocaleString() }}
      </span>

      <template v-if="collectionItem.pinned">
        <span
            data-toggle="tooltip"
            title="Remove pinned saved search"
            @click="() => unpinCollection(collectionItem)"
            style="cursor: pointer"
        >
          <span class="active" >
            <bookmark-icon/>
          </span>
        </span>
      </template>
      <template v-else>
        <span
            data-toggle="tooltip"
            title="Pin saved search: display automatically on login"
            @click="() => pinCollection(collectionItem)"  style="cursor: pointer"
        >
          <span class="inactive">
            <bookmark-icon/>
          </span>
        </span>
      </template>


      <template v-if="collectionItem.is_shared">
        <span
            data-toggle="tooltip"
            title="Unshare with company"
            @click="() => un_shareCollection(collectionItem)" style="cursor: pointer"
        >
          <span class="active">
            <share-icon/>
          </span>
        </span>
      </template>
      <template v-else>
        <span
            data-toggle="tooltip"
            title="Share with company"
            @click="() => share_Collection(collectionItem)" style="cursor: pointer"
        >
          <span class="inactive">
            <share-icon/>
          </span>
        </span>
      </template>


      <span
          data-toggle="tooltip"
          title="Edit saved search"
          @click="() => editItem(collectionItem)" style="cursor: pointer"
      >
        <edit2-icon/>
      </span>

      <span
          data-toggle="tooltip"
          title="Remove saved search"
          @click="showingDeletion = true"
          style="cursor: pointer"
      >
        <x-icon/>
      </span>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import {BookmarkIcon, Edit2Icon, ShareIcon, XIcon} from "vue-feather-icons";
import {getAllUrlParamsOnLoad, getSearchValue,} from "@/helpers/urlParamsHelper";
import store from "@/store";
import {truncateString} from "@/helpers/stringHelpers";
import {generate_base_article_url, getAllStatesAgenciesAndAlertTypes,} from "@/store/services/articleService";
import moment from "moment";

export default {
  name: "SavedSearchRow",
  components: {
    BookmarkIcon,
    Edit2Icon,
    XIcon,
    ShareIcon
  },

  props: {
    collectionItem: Object,
  },
  computed: {
    // ...mapGetters('collections', ['returnCountIsLoading']),
  },
  data() {
    return {
      showingDeletion: false,
      moment: moment,
    };
  },

  methods: {
    calculate_date_from_now(data) {
      return this.moment(data).fromNow();
    },
    loadNewCollection(collectionId, params, collectionName, count, last_count, collectionItem) {
      this.$store.dispatch("application/setApplicationLoadingState", true);
      this.$store.dispatch("collections/setCollectionItem", collectionItem);
      this.$store.dispatch("collections/setShowingModal", false);
      try {
        let url = new URL(window.location.href);
        if (!params.startsWith("?")) {
          params = "?" + params;
        }
        // Build new URL required
        const base_url = generate_base_article_url();
        const newUrl = `${base_url}${params}&savedSearchName=${collectionName}&last_viewed=${collectionItem.modified}`;

        history.replaceState("", "", newUrl);

        // Find all parameters and update the state
        const searchValues = getAllUrlParamsOnLoad();
        const searchValue = getSearchValue();

        // return false

        store.dispatch("search/updateAllSearchOptions", searchValues);
        store.dispatch("search/updateSearchValue", searchValue);
        store.dispatch("search/setSavedSearchName", collectionName);

        // Fetch all articles based on search options
        store.dispatch("filters/resetAllSelectedFiltersSidebar");

        store.dispatch("articles/setCollectionId", collectionId);
        store.dispatch("articles/searchArticles");
      } catch (error) {
        this.$store.dispatch("application/setApplicationLoadingState", false);
        alert("There was an issue updating your viewed collection.");
      }
    },
    editItem(collectionItem) {
      this.fetch_all_options();
      this.$store.dispatch("editSavedSearch/setPreviousState");
      this.$store.dispatch("editSavedSearch/setSavedItemDetails", {
        ...collectionItem,
      });
      this.$store.dispatch("editSavedSearch/setShowingModal", true);
    },

    fetch_all_options() {
      // Fetches all the filter options required
      getAllStatesAgenciesAndAlertTypes().then((response) => {
        this.$store.dispatch(
            "search/setAllAgencies",
            response.data["all_agencies"]
        );
        this.$store.dispatch(
            "search/setAllStates",
            response.data["all_states"]
        );
        this.$store.dispatch(
            "search/setAllCountries",
            response.data["all_countries"]
        );
        this.$store.dispatch(
            "search/setAllAlertTypes",
            response.data["all_alerts"]
        );
      });
    },

    removeItem(itemId) {
      this.$store.dispatch("collections/removeUserCollectionById", itemId.id);
      this.$store.dispatch("collections/fetchAllCounts");
    },

    loadCollection(collectionId, params, collectionName, count, collectionItem) {
      this.$store.dispatch("application/setApplicationLoadingState", true);
      this.$store.dispatch("collections/setCollectionItem", null);
      this.$store.dispatch("collections/setShowingModal", false);
      try {
        if (!params.startsWith("?")) {
          params = "?" + params;
        }
        let url = new URL(window.location.href);
        // Build new URL required
        const base_url = generate_base_article_url();
        const newUrl = `${base_url}${params}&savedSearchName=${collectionName}`;
        history.replaceState("", "", newUrl);

        // Find all parameters and update the state
        const searchValues = getAllUrlParamsOnLoad();
        const searchValue = getSearchValue();
        store.dispatch("search/updateAllSearchOptions", searchValues);
        store.dispatch("search/updateSearchValue", searchValue);
        store.dispatch("search/setSavedSearchName", collectionName);

        // Fetch all articles based on search options
        store.dispatch("filters/resetAllSelectedFiltersSidebar");

        store.dispatch("articles/setCollectionId", collectionId);
        store.dispatch("articles/searchArticles");
      } catch (error) {
        this.$store.dispatch("application/setApplicationLoadingState", false);
        alert("There was an issue updating your viewed collection.");
      }
    },

    pinCollection(item) {
      this.$store.dispatch("collections/setIsLoading", true);
      axios
          .post(
              "/collections/pin",
              {itemId: item.id},
              {withCredentials: true}
          )
          .then(() => {
            this.$store.dispatch("collections/fetchAllUserCollections");
            this.$store.dispatch("collections/fetchAllCounts");

          });
    },

    unpinCollection(item) {
      this.$store.dispatch("collections/setIsLoading", true);
      axios
          .put("/collections/pin", {itemId: item.id}, {withCredentials: true})
          .then(() => {
            this.$store.dispatch("collections/fetchAllUserCollections");
            this.$store.dispatch("collections/fetchAllCounts");
          });
    },
    share_Collection(item) {
      this.$store.dispatch("collections/setIsLoading", true);
      axios
          .post(
              "/collections/share",
              {itemId: item.id},
              {withCredentials: true}
          )
          .then(() => {
            this.$store.dispatch("collections/fetchAllUserCollections");
            this.$store.dispatch("collections/fetchAllCounts");
            this.$store.dispatch('notifications/pushNotification', {
              message: `${item.name} Shared Successfully`,
              type: "green"
            });
          });
    },
    un_shareCollection(item) {
      this.$store.dispatch("collections/setIsLoading", true);
      axios
          .put("/collections/share", {itemId: item.id}, {withCredentials: true})
          .then(() => {
            this.$store.dispatch("collections/fetchAllUserCollections");
            this.$store.dispatch("collections/fetchAllCounts");
            this.$store.dispatch('notifications/pushNotification', {
              message: `${item.name} Unshared Successfully`,
              type: "green"
            });
          });
    },

    truncateSavedSearch(savedSearchName) {
      if (savedSearchName.length > 15) {
        return `${truncateString(savedSearchName, 15)}...`;
      }
      return savedSearchName;
    },
  },
};
</script>

<style lang="scss" scoped>
.item-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
}

.text-regalytics-green {
  color: #4a3288;
}

.collection-link {
  font-size: 0.9rem;
  color: grey;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  flex-direction: column;

  .items-subtitle {
    font-size: 0.5rem;
    text-align: left;
  }

  &:hover {
    color: black;
  }
}

.deletion-content {
  font-size: 0.9rem;
  color: #db6060;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .confirm-delete {
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      color: #a5a5a5;
    }

    &:nth-last-child(2) {
      margin-right: 10px;
    }
  }
}

.item-right {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    transition: 0.2s;

    .active {
      color: #4a3288 !important;
    }

    .inactive {
      fill: #5b5b5b;
    }

    svg {
      width: 20px;
      transition: 0.2s;

      &:hover {
        color: #a4a4a4;
      }
    }
  }
}
</style>

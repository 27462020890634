<template>
  <div class="my-3">
    <p class="mb-0 font-weight-bold">Agency Classification</p>
    <div class="form-check form-check-inline">
      <input type="checkbox" class="form-check-input" v-model="itemDetails.sro" @change="updateStatus">
      <label>SRO</label>
    </div>
    <div class="form-check form-check-inline">
      <input type="checkbox" class="form-check-input" v-model="itemDetails.federal" @change="updateStatus">
      <label>Federal</label>
    </div>
    <div class="form-check form-check-inline">
      <input type="checkbox" class="form-check-input" v-model="itemDetails.state" @change="updateStatus">
      <label>State</label>
    </div>
    <div class="form-check form-check-inline">
      <input type="checkbox" class="form-check-input" v-model="itemDetails.territory" @change="updateStatus">
      <label>Territory</label>
    </div>
    <div class="form-check form-check-inline">
      <input type="checkbox" class="form-check-input" v-model="itemDetails.multinational" @change="updateStatus">
      <label>Multinational</label>
    </div>
    <div class="form-check form-check-inline">
      <input type="checkbox" class="form-check-input" v-model="itemDetails.county" @change="updateStatus">
      <label>County</label>
    </div>
    <div class="form-check form-check-inline">
      <input type="checkbox" class="form-check-input" v-model="itemDetails.international" @change="updateStatus">
      <label>International</label>
    </div>
  </div>
</template>

<script>
import {addOrUpdateParameter} from "@/helpers/urlParamsHelper";

export default {
  name: "Classification",

  computed: {
    itemDetails: function() {
      return this.$store.getters["search/getClassifications"];
    },

    showingSRO: function() {
      let isShowing = true;
      if (this.itemDetails.federal) isShowing = false;
      if (this.itemDetails.state) isShowing = false;
      if (this.itemDetails.territory) isShowing = false;
      return isShowing;
    },

    showingFederal: function() {
      let isShowing = true;
      if (this.itemDetails.sro) isShowing = false;
      if (this.itemDetails.state) isShowing = false;
      if (this.itemDetails.territory) isShowing = false;
      return isShowing;
    },

    showingState: function() {
      let isShowing = true;
      if (this.itemDetails.sro) isShowing = false;
      if (this.itemDetails.federal) isShowing = false;
      if (this.itemDetails.territory) isShowing = false;
      return isShowing;
    },

    showingTerritory: function() {
      let isShowing = true;
      if (this.itemDetails.sro) isShowing = false;
      if (this.itemDetails.federal) isShowing = false;
      if (this.itemDetails.state) isShowing = false;
      return isShowing;
    },



  },

  methods: {

    // Sends a request to dispatcher to update the current search filters
    updateStatus() {
      let temp_state = {...this.itemDetails};
      this.$store.dispatch('search/updateClassificationValues', {
        sro: temp_state.sro,
        federal: temp_state.federal,
        state: temp_state.state,
        territory: temp_state.territory,
        county: temp_state.county,
        international: temp_state.international,
        multinational: temp_state.multinational,

      });

      const encodedParams = encodeURI(`${temp_state.sro}|${temp_state.federal}|${temp_state.state}|${temp_state.territory}|${temp_state.international}|${temp_state.county}|${temp_state.multinational}`)
      addOrUpdateParameter("classification", encodedParams);
    }
  }

}
</script>

<template>
  <div class="text-end">
    <p v-if="!getSavedSearchName.length > 0" class="total-articles mb-1">
      <i>{{ getTotalResults.toLocaleString() }}</i> articles found
    </p>
    <p v-else class="total-articles mb-1">
      <template v-if="getSavedSearchName.length < 15">
        <i>{{ getTotalResults.toLocaleString() }}</i> articles found in <em>{{ getSavedSearchName }}</em>
      </template>
      <template v-else>
        <i>{{ getTotalResults.toLocaleString() }}</i> articles found in
        <em :title="getSavedSearchName" data-placement="top"
            data-toggle="tooltip" style="cursor: help;">{{
            shortenSavedSearchName(getSavedSearchName)
          }}...</em>
      </template>
    </p>
  </div>
</template>

<script>
import {truncateString} from "@/helpers/stringHelpers";
import {mapGetters} from "vuex";

export default {
  name: "TotalArticles",

  computed: {
    ...mapGetters('articles', ['getTotalResults']),
    ...mapGetters('search', ['getSavedSearchName'])
  },

  methods: {
    shortenSavedSearchName(savedSearchName) {
      return truncateString(savedSearchName, 15);
    }
  }

}
</script>

<style scoped>

</style>

<template>
  <div class="my-3">
    <p class="mb-0 font-weight-bold">SEC Release Number</p>
    <input :value="statusDetails" class="form-control form-control-sm" type="text"
           @change="(event) => updateStatus(event.target.value)">
  </div>
</template>

<script>
import {addOrUpdateParameter} from "@/helpers/urlParamsHelper";

export default {
  name: "SecReleaseNumber",

  computed: {
    statusDetails: function () {
      return this.$store.getters["search/getSec_release_number"];
    }
  },

  methods: {
    updateStatus(value) {
      this.$store.dispatch('search/updateSec_release_number', value);

      // Adds the new value as parameters to the URI
      const encodedParams = encodeURI(value);
      addOrUpdateParameter("sec_release_number", encodedParams);
    }
  }
}
</script>
<template>
  <div class="all-collections">
    <div class="collection-items">
      <div v-if="returnIsLoading">
        Loading...
      </div>
      <div v-else>
        <div v-if="allCuratedCollections.length <= 0">
          <small>You don't have any popular searches...</small>
        </div>
        <div v-for="collectionItem in allCuratedCollections" v-else :key="collectionItem.id">
          <curated-search-row :collection-item="collectionItem" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CuratedSearchRow from "@/components/saved-searches-modal/CuratedSearchRow";

export default {
  name: "AllCuratedSearches",
  components: { CuratedSearchRow },
  computed: {
    allCuratedCollections: function () {
      return this.$store.getters["collections/returnAllCuratedCollections"];
    },
    ...mapGetters('collections', ['returnIsLoading'])
  }

}
</script>

<style scoped>
.all-collections {
  padding: 10px 10px;
  width: 100%;
}
</style>

import Vue from 'vue';
import ArticleCard from "@/components/articles/articles-card/ArticleCard.vue";
import {mapGetters} from "vuex";
import ArticlesPagination from "@/components/articles/articles-pagination/ArticlesPagination.vue";
import ArticlesFilterSidebar from "@/components/articles/articles-filters-sidebar/ArticlesFilterSidebar.vue";
import Spinner from "@/components/Spinner.vue";
import {ArticlesLayout} from "@/types/account/User"
import ArticleCardSmall from "@/components/articles/articles-card/ArticleCardSmall.vue";
import ArticleCardTable from "@/components/articles/articles-card/ArticleCardTable.vue";

export default Vue.extend({
  name: "ArticlesTable",
  components: {
    ArticleCardTable,
    ArticleCardSmall,
    Spinner,
    ArticlesFilterSidebar,
    ArticlesPagination,
    ArticleCard,
  },

  data() {
    return {
      articlesLayout: ArticlesLayout,
      search_elements:[]
    }
  },

  computed: {
    ...mapGetters('articles', ['getResults', 'getIsFetchingArticles']),
    ...mapGetters('filters', ['getFiltersSidebarOpenState','get']),
    ...mapGetters('application', ['getIsMobile']),
    ...mapGetters('accounts', ['getCurrentUser']),


    determineClassStyle() {
      if (this.getIsMobile) return 'articles-table-sidebar-closed';
      if (!this.getIsMobile && this.getFiltersSidebarOpenState) {
        return 'articles-table-sidebar-open'
      }
      return 'articles-table-sidebar-closed';
    }

  }
});

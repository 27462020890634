var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-row"},[_c('div',[(_vm.showingDeletion)?_c('div',{staticClass:"deletion-content"},[_c('span',{staticClass:"me-3"},[_vm._v("Delete \""+_vm._s(_vm.truncateSavedSearch(_vm.collectionItem.name))+"\"?")]),_c('span',[_c('span',{staticClass:"confirm-delete",on:{"click":() => _vm.removeItem(_vm.collectionItem)}},[_vm._v("Yes")]),_c('span',{staticClass:"confirm-delete",on:{"click":function($event){_vm.showingDeletion = false}}},[_vm._v("No")])])]):_c('div',{staticClass:"collection-link",on:{"click":() =>
          _vm.loadCollection(
            _vm.collectionItem.id,
            _vm.collectionItem.parameters,
            _vm.collectionItem.name,
            _vm.collectionItem.count,
            _vm.collectionItem
          )}},[_c('span',{staticClass:"fs-6"},[_vm._v(_vm._s(_vm.collectionItem.name))]),(_vm.collectionItem.modified != null)?_c('span',{staticClass:"items-subtitle"},[_vm._v(" Last viewed "+_vm._s(new Date(_vm.collectionItem.modified).toDateString()))]):_c('span',{staticClass:"items-subtitle"},[_vm._v("Created on "+_vm._s(new Date(_vm.collectionItem.created).toDateString()))])])]),_c('div',{staticClass:"item-right"},[_c('span',{staticClass:"text-regalytics-green small me-1"},[(_vm.collectionItem.last_count > 0)?[(_vm.collectionItem.count - _vm.collectionItem.last_count > 0)?[_c('span',{staticStyle:{"cursor":"pointer","border":"1px dotted","padding":"2px"},attrs:{"data-placement":"top","data-toggle":"tooltip","title":"Total new Articles since last checked"},on:{"click":function($event){return _vm.loadNewCollection(
                _vm.collectionItem.id,
                _vm.collectionItem.parameters,
                _vm.collectionItem.name,
                _vm.collectionItem.count,
                _vm.collectionItem.last_count,
                _vm.collectionItem
              )}}},[_vm._v(" +"+_vm._s(( parseInt( _vm.collectionItem.count.toString().split(",").join("") ) - _vm.collectionItem.last_count ).toLocaleString())+" ")])]:_vm._e()]:_vm._e()],2),_c('span',{staticClass:"badge badge-pill text-dark fw-light",attrs:{"data-placement":"top","data-toggle":"tooltip","title":"Total number of Articles in saved search"}},[_vm._v(" "+_vm._s(_vm.collectionItem.count.toLocaleString())+" ")]),(_vm.collectionItem.pinned)?[_c('span',{staticStyle:{"cursor":"pointer"},attrs:{"data-toggle":"tooltip","title":"Remove pinned saved search"},on:{"click":() => _vm.unpinCollection(_vm.collectionItem)}},[_c('span',{staticClass:"active"},[_c('bookmark-icon')],1)])]:[_c('span',{staticStyle:{"cursor":"pointer"},attrs:{"data-toggle":"tooltip","title":"Pin saved search: display automatically on login"},on:{"click":() => _vm.pinCollection(_vm.collectionItem)}},[_c('span',{staticClass:"inactive"},[_c('bookmark-icon')],1)])],(_vm.collectionItem.is_shared)?[_c('span',{staticStyle:{"cursor":"pointer"},attrs:{"data-toggle":"tooltip","title":"Unshare with company"},on:{"click":() => _vm.un_shareCollection(_vm.collectionItem)}},[_c('span',{staticClass:"active"},[_c('share-icon')],1)])]:[_c('span',{staticStyle:{"cursor":"pointer"},attrs:{"data-toggle":"tooltip","title":"Share with company"},on:{"click":() => _vm.share_Collection(_vm.collectionItem)}},[_c('span',{staticClass:"inactive"},[_c('share-icon')],1)])],_c('span',{staticStyle:{"cursor":"pointer"},attrs:{"data-toggle":"tooltip","title":"Edit saved search"},on:{"click":() => _vm.editItem(_vm.collectionItem)}},[_c('edit2-icon')],1),_c('span',{staticStyle:{"cursor":"pointer"},attrs:{"data-toggle":"tooltip","title":"Remove saved search"},on:{"click":function($event){_vm.showingDeletion = true}}},[_c('x-icon')],1)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }
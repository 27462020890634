<template>
  <div class="my-3">
    <p class="mb-0 font-weight-bold">Sectors</p>
    <v-select
      multiple
      label="name"
      :options="allSectorTypes"
      :value="selectedItems"
      @input="updateTags"
      :select-on-tab="true"
      :close-on-select="false"
      :clearSearchOnSelect="false"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import { addOrUpdateParameter } from "@/helpers/urlParamsHelper";

export default {
  name: "SectorTypes",

  components: {
    vSelect,
  },

  computed: {
    allSectorTypes: function () {
      return this.$store.getters["search/getAllSectorTypes"];
    },
    selectedItems: function () {
      return this.$store.getters["search/getSectorTypeTags"];
    },
  },

  methods: {
    updateTags(value) {
      this.updateItemsInStore(value);
    },

    updateItemsInStore(value) {
      this.$store.dispatch("search/updateSectorTypes", value);

      // Grabs all but the last item
      const allButLast = value.slice(0, -1);
      // Builds a string for all states
      let allStatesString = "";
      for (let item of allButLast) {
        allStatesString += item.name + "|";
      }

      // Adds the last item to string without the "|" delimiter
      value.length >= 1
        ? (allStatesString += value[value.length - 1].name)
        : "";

      const encodedParams = encodeURI(`${allStatesString}`);
      addOrUpdateParameter("sector_type", encodedParams);
    },
  },
};
</script>

<template>
  <div class="all-collections">
    <div class="collection-items">
      <div v-if="returnIsLoading">
        Loading...
      </div>
      <div v-else>
        <div v-if="allCollections.length <= 0">
          <small>You don't have any saved searches...</small>
        </div>
        <div v-for="collectionItem in allCollections" v-else :key="collectionItem.id">
          <saved-search-row :collection-item="collectionItem" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SavedSearchRow from "@/components/saved-searches-modal/SavedSearchRow";

export default {
  name: "AllSavedSearches",
  components: {SavedSearchRow},
  computed: {
    allCollections: function () {
      return this.$store.getters["collections/returnAllCollections"];
    },
    ...mapGetters('collections', ['returnIsLoading'])
  }

}
</script>

<style scoped>
.all-collections {
  padding: 10px 10px;
  width: 100%;
}
</style>
<template>
  <div class="icon-styling" :class="{ 'disabled': isDisabled }" @mouseover="showingPopover = true"
    @mouseleave="showingPopover = false" data-bs-toggle="tooltip" data-bs-placement="top" title="Download report">

    <!-- Disable the button based on license type -->
    <download-icon v-if="!isDownloading" @click="generateReport" :class="{ 'disabled': isDisabled }"
      :disabled="isDisabled" @mouseover.stop.prevent="checkDisabledTooltip" />

    <loader-icon v-else />

    <custom-pop-over :left="-20" v-if="showingPopover && !isDisabled">
      Download Articles
    </custom-pop-over>
  </div>
</template>

<script>
import axios from "@/axios";
import CustomPopOver from "@/components/CustomPopOver";
import { findAllSelectedItems } from "@/helpers/filtersHelper";
import store from "@/store";
import fileDownload from "js-file-download";
import { DownloadIcon, LoaderIcon } from 'vue-feather-icons';
import { mapGetters } from "vuex";

export default {
  name: "DownloadArticles",
  components: { CustomPopOver, DownloadIcon, LoaderIcon },
  computed: {
    ...mapGetters('accounts', ['getCurrentUser']),

    // Computed property to check if the download button should be disabled
    isDisabled() {
      return this.getCurrentUser.downloads === false;
    }
  },
  data() {
    return {
      showingPopover: false,
      isDownloading: false,
      reportIsReady: false
    }
  },
  methods: {
    checkDisabledTooltip(event) {
      if (this.isDisabled) {
        this.$store.dispatch('notifications/pushNotification', {
          message: `Your license does not allow downloading reports.`,
          type: 'warning'
        });
      }
    },
    generateReport() {
      if (this.isDisabled) {
        return; // Prevent report generation if the button is disabled
      }

      // Prevent downloading multiple times at once
      if (!this.isDownloading) {
        this.isDownloading = true;
        let current_url = new URL(window.location.href);
        const currentFilters = this.$store.getters["search/getAllFilters"];
        const searchValue = this.$store.getters["search/getSearchValue"];
        const allFiltersOnSidebar = this.$store.getters["filters/getCurrentFilters"];
        const allSelectedItems = findAllSelectedItems(allFiltersOnSidebar);
        const filteredBy = this.$store.getters['articles/getIsFilteredBy'];
        const lastViewed = current_url.searchParams.get("last_viewed");

        store.dispatch('notifications/pushNotification', {
          message: `Starting to download the report...`,
          type: 'primary'
        });
        axios.post('/articles/generate-report', {
          searchFilters: currentFilters,
          searchValue: searchValue,
          filters: allSelectedItems,
          sortBy: filteredBy,
          lastViewed: lastViewed
        }, { withCredentials: true })
          .then(response => {
            const taskId = response.data.id;
            this.checkReportStatus(taskId);
          })
          .catch(err => {
            if (err.response.status === 426) {
              this.$store.dispatch('notifications/pushNotification', {
                message: `You've already downloaded the max excel files for your account today!`,
                type: 'danger'
              }, { root: true });
              this.isDownloading = false;
            } else {
              this.$store.dispatch('notifications/pushNotification', {
                message: `An unknown error has occurred. Please try again.`,
                type: 'danger'
              }, { root: true });
              this.isDownloading = false;
            }
          });
      }
    },

    checkReportStatus(taskId) {
      axios.get(`/articles/generate-report?task_id=${taskId}`, { withCredentials: true })
        .then(response => {
          const taskStatus = response.data.task_status;

          switch (taskStatus) {
            case "SUCCESS":
              this.downloadFile(taskId);
              return false;
            case "FAILURE":
              store.dispatch('notifications/pushNotification', {
                message: `There was an error downloading this report`,
                type: 'danger'
              });
              return false;
            default:
              break;
          }

          let self = this;
          setTimeout(function () {
            self.checkReportStatus(response.data.task_id);
          }, 3000);
        });
    },

    downloadFile(taskId) {
      axios.put(`/articles/generate-report?task_id=${taskId}`, {}, { withCredentials: true, responseType: "blob" })
        .then(response => {
          fileDownload(response.data, 'report.xlsx');
          this.isDownloading = false;

          this.$tracking.trackEvent('downloadedArticles', this.$store.getters['search/getCurrentSearchOptions']);

          store.dispatch('notifications/pushNotification', {
            message: `Successfully downloaded the report!`,
            type: 'green'
          });
        });
    }
  }
}
</script>

<style scoped>
.icon-styling {
  position: relative;
}

.disabled {
  pointer-events: none !important;
  opacity: 0.6;
}
</style>

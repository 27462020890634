<template>
  <div class="dropdown">
    <button id="dropdownMenuButton" aria-expanded="false" class="btn btn-sm dropdown-toggle icon-styling"
            data-bs-toggle="dropdown" type="button" @mouseover="showingPopover = true"
            @mouseleave="showingPopover = false">
      <layers-icon/>
    </button>
    <ul aria-labelledby="dropdownMenuButton" class="dropdown-menu dropdown-menu-start">
      <li><span class="dropdown-item" :class="{'active': getIsFilteredBy === 'newest'}" @click="orderByNewest">Newest</span></li>
      <li><span class="dropdown-item" :class="{'active': getIsFilteredBy === 'oldest'}" @click="orderByOldest">Oldest</span></li>
      <li><span class="dropdown-item" :class="{'active': getIsFilteredBy === 'comments_due'}" @click="orderByCommentsDue">Comments Due</span></li>
    </ul>

    <custom-pop-over
        :left="-50"
        v-if="showingPopover">
      Sort Articles
    </custom-pop-over>
  </div>
</template>

<script>
import CustomPopOver from "@/components/CustomPopOver";
import {LayersIcon} from 'vue-feather-icons';
import {mapGetters} from "vuex";

export default {
  name: "SortBy",
  components: {CustomPopOver, LayersIcon},

  data() {
    return {
      showingPopover: false
    }
  },

  computed: {
    ...mapGetters('articles', ['getIsFilteredBy']),
  },

  methods: {

    // Orders the articles by the most "recent update"
    orderByNewest() {
      this.$store.dispatch('articles/updateSortingOption', 'newest');
    },

    // Orders the articles by the oldest "recent update"
    orderByOldest() {
      this.$store.dispatch('articles/updateSortingOption', 'oldest');
    },

    // Orders the articles by the newest "comments due"
    orderByCommentsDue() {
      this.$store.dispatch('articles/updateSortingOption', 'comments_due');
    }

  }


}
</script>

<style lang="scss" scoped>
.dropdown-item {
  &:active {
    background: #eaeaea !important;
  }
}

.active {
  background: #4a3288!important;
}
</style>

<template>
  <div class="my-3">
    <p class="mb-1 fw-bold">Originally Published</p>
    <div class="d-flex justify-content-between">
      <div class="form-inline me-4">
        <label class="mr-2">Starting Date: </label>
        <input type="date" class="form-control" :value="statusDetails.start" @change="(event) => updateStartingDate(event.target.value)">
      </div>
      <div class="form-inline">
        <label class="mr-2">Ending Date:</label>
        <input type="date" class="form-control" :value="statusDetails.end" @change="(event) => updateEndingDate(event.target.value)">
      </div>
    </div>
  </div>
</template>

<script>
import {addOrUpdateParameter} from "@/helpers/urlParamsHelper";


export default {
  name: "OriginallyPublished",

  computed: {
    statusDetails: function() {
      return this.$store.getters["search/getOriginallyPublished"];
    }
  },

  methods: {

    // Sets the starting date for the latest update
    updateStartingDate(value) {
      this.$store.dispatch('search/updateOriginallyPublishedDateStart', value);

      // Adds the new value as parameters to the URI
      const encodedParams = encodeURI(value);
      addOrUpdateParameter("originallyPublishedStart", encodedParams);
    },

    // Sets the ending date for the latest update
    updateEndingDate(value) {
      this.$store.dispatch('search/updateOriginallyPublishedDateEnd', value);

      // Adds the new value as parameters to the URI
      const encodedParams = encodeURI(value);
      addOrUpdateParameter("originallyPublishedEnd", encodedParams);
    }

  }

}
</script>

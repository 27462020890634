<template>
  <div class="my-3">
    <p class="mb-0 font-weight-bold">Status</p>
    <div class="form-check form-check-inline">
      <input v-model="statusDetails.new"
             class="form-check-input" type="checkbox" @change="updateStatus">
      <label>New</label>
    </div>
    <div class="form-check form-check-inline">
      <input v-model="statusDetails.updated"
             class="form-check-input" type="checkbox" @change="updateStatus">
      <label>Updated</label>
    </div>
  </div>
</template>

<script>
import {addOrUpdateParameter} from "@/helpers/urlParamsHelper";

export default {
  name: "Status",
  computed: {
    statusDetails: function() {
      return this.$store.getters["search/getStatus"];
    }
  },

  methods: {
    updateStatus() {

      // Update the state in the Vuex store
      let temp_state = {...this.statusDetails};
      this.$store.dispatch('search/updateStatusValues', {
        new: temp_state.new,
        updated: temp_state.updated
      })

      const encodedParams = encodeURI(`${temp_state.new}|${temp_state.updated}`)
      addOrUpdateParameter("status", encodedParams);
    }
  }
}
</script>

<template>
  <div class="item-row">
    <div>
      <div class="collection-link" @click="
        () =>
          loadCollection(
            collectionItem.id,
            collectionItem.parameters,
            collectionItem.name,
            collectionItem.count,
            collectionItem
          )
      ">
        <span class="fs-6">{{ collectionItem.name }}</span>
        <span class="items-subtitle">Created on
          {{ new Date(collectionItem.created).toDateString() }}</span>
      </div>
    </div>
    <div class="item-right">
      <span data-toggle="tooltip" title="Copy to my saved searches" @click="() => copyCollection(collectionItem)">
        <copy-icon/>
      </span>
    </div>
  </div>
</template>


<script>
import axios from "@/axios";
import {CopyIcon} from "vue-feather-icons";
import {getAllUrlParamsOnLoad, getSearchValue,} from "@/helpers/urlParamsHelper";
import store from "@/store";
import {generate_base_article_url,} from "@/store/services/articleService";


export default {
  name: "CuratedSearchRow",
  components: {
    CopyIcon,
  },

  props: {
    collectionItem: Object,
  },
  computed: {
    // ...mapGetters('collections', ['returnCountIsLoading']),
  },
  data() {
    return {};
  },

  methods: {
    loadCollection(collectionId, params, collectionName, count, collectionItem) {
      this.$store.dispatch("application/setApplicationLoadingState", true);
      this.$store.dispatch("collections/setCollectionItem", null);
      this.$store.dispatch("collections/setShowingModal", false);
      try {
        if (!params.startsWith("?")) {
          params = "?" + params;
        }
        let url = new URL(window.location.href);
        // Build new URL required
        const base_url = generate_base_article_url();
        const newUrl = `${base_url}${params}&savedSearchName=${collectionName}`;
        history.replaceState("", "", newUrl);

        // Find all parameters and update the state
        const searchValues = getAllUrlParamsOnLoad();
        const searchValue = getSearchValue();
        store.dispatch("search/updateAllSearchOptions", searchValues);
        store.dispatch("search/updateSearchValue", searchValue);
        store.dispatch("search/setSavedSearchName", collectionName);

        // Fetch all articles based on search options
        store.dispatch("filters/resetAllSelectedFiltersSidebar");

        store.dispatch("articles/setCollectionId", collectionId);
        store.dispatch("articles/searchArticles");
      } catch (error) {
        this.$store.dispatch("application/setApplicationLoadingState", false);
        alert("There was an issue updating your viewed collection.");
      }
    },

    copyCollection(item) {

      this.$store.dispatch("collections/setIsLoading", true);
      axios
          .post(
              "/collections/clone_search",
              {itemId: item.id},
              {withCredentials: true}
          )
          .then((data) => {
            console.log(data);
            this.$store.dispatch("collections/fetchAllUserCollections");
            this.$store.dispatch("collections/fetchAllCounts");
            this.$store.dispatch('collections/fetchAllCuratedCollections');
            this.$store.dispatch('notifications/pushNotification', {
              message: `${item.name} Copied Successfully`,
              type: "green"
            });
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.item-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
}

.text-regalytics-green {
  color: #4a3288;
}

.collection-link {
  font-size: 0.9rem;
  color: grey;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  flex-direction: column;

  .items-subtitle {
    font-size: 0.5rem;
    text-align: left;
  }

  &:hover {
    color: black;
  }
}

.deletion-content {
  font-size: 0.9rem;
  color: #db6060;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .confirm-delete {
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      color: #a5a5a5;
    }

    &:nth-last-child(2) {
      margin-right: 10px;
    }
  }
}

.item-right {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    transition: 0.2s;

    .active {
      color: #4a3288 !important;
    }

    .inactive {
      fill: #5b5b5b;
    }

    svg {
      width: 20px;
      transition: 0.2s;

      &:hover {
        color: #a4a4a4;
      }
    }
  }
}
</style>

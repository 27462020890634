<template>
  <div class="add-collection-container">
    <div v-if="isLoading">
      <spinner/>
    </div>
    <div v-else>
      <form @submit="event => submitForm(event)">
        <div class="mb-2">
          <label class="small text-dark" for="name">Save Current Search Criteria</label>
          <input type="text" id="name" class="form-control form-control-sm" placeholder="Name..." v-model="collectionName" required minlength="4">
        </div>
        <div class="mb-0">
          <button type="submit" @click="button = 'save'">Save</button>
          <button type="submit" class="mt-2" @click="button = 'edit'">Save & Edit</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import axios from "@/axios";
import Spinner from "@/components/Spinner";

export default {
  name: "AddSavedSearch",
  components: {Spinner},

  data() {
    return {
      collectionName: '',
      isLoading: false,
      button: 'save'
    }
  },

  methods: {
    submitForm(event) {
      this.isLoading = true;
      event.preventDefault();

      // Fetch the search parameters from the URL
      const url = new URL(window.location.href);
      let searchParameters;
      if (url.search.length >= 2) {
        let tempParams = new URLSearchParams(url.search);
        tempParams.delete('savedSearchName');
        searchParameters = `?${tempParams.toString()}`;
      }


      axios.post('/collections/create', {
        name: this.collectionName,
        parameters: url.search.length >= 2 ? searchParameters : "default"
      }, {withCredentials: true})
          .then(response => {
            this.$store.dispatch('notifications/pushNotification', {message: "Added to your saved searches successfully!", type: "green"});

            if (this.button === 'edit') {
              this.showSavedSearchesModal();
              this.editItem(response.data);
            }

            // Logs that a user saved a search and the saved search name
            this.$tracking.trackEvent('saveSearch', {
              savedSearchName: this.collectionName,
            });

            this.collectionName = '';
            this.isLoading = false;
          })
          .catch(() => {
            this.$store.dispatch('notifications/pushNotification', {message: "There was an error saving this saved search! Please try again.", type: "danger"});
            this.isLoading = false;
          })
    },

    showSavedSearchesModal() {
      this.$store.dispatch('collections/setShowingModal', true);
    },

    editItem(collectionItem) {
      this.$store.dispatch('editSavedSearch/setPreviousState');
      this.$store.dispatch('editSavedSearch/setSavedItemDetails', collectionItem);
      this.$store.dispatch('editSavedSearch/setShowingModal', true);
    }

  }

}
</script>

<style lang="scss" scoped>
.add-collection-container {
  padding: 4px 3px;
  width: 100%;

  button {
    cursor: pointer;
    color: #666666;
    list-style: none;
    padding: 3px 1px;
    border: 1px solid #eee;
    border-radius: 6px;
    //box-shadow: 0 3px 3px 0 #ddd;
    font-size: 0.75rem;
    font-weight: bold;
    transition: all .2s;
    width: 100%;
    text-align: center;

    &:hover {
      color: #000000;
      box-shadow: 0 3px 3px 0 #cccccc;
    }
  }
}
</style>

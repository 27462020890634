export interface User {
    id: number;
    email: string | null;
    telemetry: boolean;
    search_highlight: boolean;
    jwtToken: string | null;
    name: string | null;
    articles_layout?: ArticlesLayout | null;
}

export enum ArticlesLayout {
    DEFAULT = "Default",
    SMALL = "Small",
    TABLE = "Table"
}

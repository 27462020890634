var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item-row"},[_c('div',[_c('div',{staticClass:"collection-link",on:{"click":() =>
        _vm.loadCollection(
          _vm.collectionItem.id,
          _vm.collectionItem.parameters,
          _vm.collectionItem.name,
          _vm.collectionItem.count,
          _vm.collectionItem
        )}},[_c('span',{staticClass:"fs-6"},[_vm._v(_vm._s(_vm.collectionItem.name))]),_c('span',{staticClass:"items-subtitle"},[_vm._v("Created on "+_vm._s(new Date(_vm.collectionItem.created).toDateString()))])])]),_c('div',{staticClass:"item-right"},[_c('span',{attrs:{"data-toggle":"tooltip","title":"Copy to my saved searches"},on:{"click":() => _vm.copyCollection(_vm.collectionItem)}},[_c('copy-icon')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="at-header-placeholder">

    <total-articles/>
    <div class="at-header">

      <transition name="fade" mode="out-in">
<!--        <div v-if="showingSearch" class="at-search" :key="'showing'">
          <div class="d-flex align-items-center">
            <form @submit.prevent="event => fetchSearchData(event)" class="w-100">
              <div class="d-flex align-items-center">
                <input type="text"
                       :value="searchValue"
                       @change="(event) => updateSearchNavigation(event.target.value)"
                       class="form-control me-3" placeholder="Search articles title and summary...">
                <span class="close" @click="showingSearch = false;">
                  <x-icon/>
                </span>
              </div>
            </form>
          </div>
        </div >-->
        <div class="at-navigation" :key="'notShowing'">
          <div class="left-icons">
            <open-filters-sidebar/>
            <download-articles/>
            <sort-by/>
            <change-layout/>
          </div>

          <div class="right-icons">
            <save-search/>
            <saved-searches/>
            <advanced-search/>
<!--            <div class="search icon-styling" @click="showingSearch = true;">
              <search-icon/>
            </div>-->
          </div>
        </div>
      </transition>

    </div>
  </div>
</template>

<script>
import TotalArticles from "@/components/articles/total-articles/TotalArticles";
import {SearchIcon, XIcon} from 'vue-feather-icons';
import {addOrUpdateParameter} from "@/helpers/urlParamsHelper";
import DownloadArticles from "@/components/articles/articles-header/all-icons/DownloadArticles";
import SaveSearch from "@/components/articles/articles-header/all-icons/SaveSearch";
import SortBy from "@/components/articles/articles-header/all-icons/SortBy";
import AdvancedSearch from "@/components/articles/articles-header/all-icons/AdvancedSearch";
import SavedSearches from "@/components/articles/articles-header/all-icons/SavedSearches";
import OpenFiltersSidebar from "@/components/articles/articles-header/all-icons/OpenFiltersSidebar";
import {logSearchHistory} from "@/store/services/articleService";
import ChangeLayout from "@/components/articles/articles-header/all-icons/ChangeLayout";

export default {
name: "ArticlesHeader",
  components: {
    OpenFiltersSidebar,
    SavedSearches,
    AdvancedSearch,
    SortBy,
    SaveSearch,
    DownloadArticles,
    TotalArticles,
    ChangeLayout
  },

  computed: {
    searchValue: function() {
      return this.$store.getters["search/getSearchValue"];
    }
  },

  data() {
    return {
      showingSearch: false
    }
  },

  methods: {

    updateSearchNavigation(value) {
      this.$store.dispatch('search/updateSearchValue', value);
      // Adds the new value as parameters to the URI
      const encodedParams = encodeURI(value);
      addOrUpdateParameter("searchValue", encodedParams);
    },

    clearSearchInput() {
      this.$store.dispatch('search/updateSearchValue', '');
      addOrUpdateParameter("searchValue", "");
    },

    // Will send a request to the dispatcher to fetch all
    // results from the database that match
    // the users search options
    fetchSearchData(event) {
      event.preventDefault();
      this.$store.dispatch('articles/setLoadingFromExternalSource', true);
      this.$store.dispatch('filters/getFilters');
      this.$store.dispatch('search/getSearchResults')
          .then(response => {
            // Builds the response in the format we require for the front-end
            let responseData = response.data.results;
            responseData.forEach((item, index) => {

              let alertType = ''

              if (item.alert_type) {
                alertType = item.alert_type;
              }

              responseData[index].alert_type = alertType;
            });
            this.$store.dispatch('articles/setLoadingFromExternalSource', false);
          })
          .then(() => {
            logSearchHistory();
          });
    }

  }

}
</script>

<style lang="scss" scoped>
.at-header-placeholder {
  padding-top: 15px;
  background: linear-gradient(180deg,hsla(0,0%,97.3%,.95) 44%,hsla(0,0%,97.3%,.46) 73%,hsla(0,0%,100%,0))!important;
  position: sticky;
  top: 0;
  margin-left: -3px;
  margin-right: -3px;
  z-index: 2;
}

.at-header {
  border-radius: .5rem;
  padding: .8rem 1rem;
  width: 100%;
  background-color: white;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;
}

.at-search {
  .close {
    cursor: pointer;
  }
}

.at-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .search {
    cursor: pointer;
  }

  .left-icons {
    display: flex;
    align-items: center;

    .icon-styling {
      margin-right: 15px;
      transition: 0.25s;

      &:hover {
        cursor: pointer;
        color: gainsboro;
      }
    }
  }

  .right-icons {
    display: flex;
    align-items: center;

    .icon-styling {
      margin-left: 15px;
      transition: 0.25s;

      &:hover {
        cursor: pointer;
        color: gainsboro;
      }
    }
  }

}

</style>

<template>
  <div class="my-3">
    <div class="d-flex justify-content-between align-items-center">
      <p class="mb-0 font-weight-bold">Includes (any of these words):</p>
      <PopOver icon="icon">
        Searches for Articles that contain any keywords in either the title, summary or agencies.
        Example: bank, money (Returns any Articles that contain either "bank" or "money")
      </PopOver>
    </div>
    <v-select
        :noDrop="true"
        :value="allIncludeKeywords"
        multiple
        placeholder="Enter keyword and press enter..."
        taggable
        @input="updateTags"
    />
  </div>
</template>

<script>
import vSelect from 'vue-select';
import {addOrUpdateParameter} from "@/helpers/urlParamsHelper";
import PopOver from "@/components/articles/articles-search-options/PopOver";


export default {
  name: "OrIncludes",

  components: {
    PopOver,
    vSelect
  },

  computed: {
    allIncludeKeywords: function () {
      return this.$store.getters["search/getAllOrIncludeKeywords"];
    }
  },

  methods: {

    updateTags(value) {
      this.$store.dispatch('search/setOrIncludeKeywords', value);

      // Grabs all but the last item
      const allButLast = value.slice(0, -1);
      // Builds a string for all include keywords
      let allItemsString = "";
      for (let item of allButLast) {
        allItemsString += item + "|";
      }

      // Adds the last item to string without the "|" delimiter
      value.length >= 1 ? allItemsString += value[value.length - 1] : "";

      const encodedParams = encodeURI(`${allItemsString}`)
      addOrUpdateParameter("orIncludes", encodedParams);

    }

  }
}
</script>

<template>
  <div class="article-card">
    <div class="ac-header">
      <div class="ac-title">

        <div class="d-flex  justify-content-between align-items-center mb-1">
          <p class="small mb-0">
            {{ new Date(article.latest_update).toLocaleString('default', {month: 'long', timeZone: 'UTC'}) }}
            {{ article.latest_update.split('-')[2] }}, {{ new Date(article.latest_update).getUTCFullYear() }}
          </p>
          <div class="small d-flex align-items-center">
            <span :class="`badge article-alert-${article.status.toLowerCase()}`">
              {{ article.status }}
            </span>
          </div>
        </div>

        <div class="article-card-title" @click="() => loadArticle(article.id)">
          <h5 v-if="article.title.length > 80" v-html="shortenTitle(article.title)">...</h5>
          <h5 v-else v-html="normal_title(article.title)"></h5>
          <p class="text-green mb-0 fw-bold">{{ createAgenciesString(article.agencies) }}</p>
        </div>
      </div>
    </div>

    <div class="ac-body" @click="() => loadArticle(article.id)">
      <p class="mb-1" data-bs-toggle="tooltip" data-bs-placement="top" :title="article.summary">
        <span v-if="article.summary.length > 150" v-html="shortenSummary(article.summary)">...</span>
        <span v-else v-html="normal_summary(article.summary)"></span>
      </p>
    </div>

    <div class="social-icons share-icons-mobile">
      <social-buttons-small :article="article"/>
      <article-card-save :article="article"/>
    </div>

  </div>
</template>

<script>
import ArticleCardSave from "@/components/articles/articles-card/article-card-save/ArticleCardSave";
import {createAgenciesString, truncateString} from "@/helpers/stringHelpers";
import {logViewedArticle} from "@/store/services/articleService";
import SocialButtonsSmall from "@/components/articles/articles-card/SocialButtonsSmall";

export default {
  name: "ArticleCardSmall",
  components: {SocialButtonsSmall, ArticleCardSave},

  props: {
    article: Object
  },

  methods: {
    normal_title(title) {
      let search_terms = this.$store.getters["search/getAllIncludeKeywords"].concat(this.$store.getters["search/getAllOrIncludeKeywords"])
      if (search_terms.length <= 0) {
        return title;
      }
      let current_user = this.$store.getters["accounts/getCurrentUser"];
      if (current_user.search_highlight) {
        var regexFromMyArray = new RegExp(search_terms.join("|"), 'gi');
        return title.replace(regexFromMyArray, match => {
          var colors = ['yellow'];
          var random_color = colors[Math.floor(Math.random() * colors.length)];
          return '<span style="background: ' + random_color + '">' + match + '</span>';
        });
      } else {
        return title;
      }

    },
    normal_summary(summary) {

      let search_terms = this.$store.getters["search/getAllIncludeKeywords"].concat(this.$store.getters["search/getAllOrIncludeKeywords"])
      if (search_terms.length <= 0) {
        return summary;
      }
      let current_user = this.$store.getters["accounts/getCurrentUser"];
      if (current_user.search_highlight) {
        var regexFromMyArray = new RegExp(search_terms.join("|"), 'gi');
        return summary.replace(regexFromMyArray, match => {
          var colors = ['yellow'];
          var random_color = colors[Math.floor(Math.random() * colors.length)];
          return '<span style="background: ' + random_color + '">' + match + '</span>';
        });
      } else {
        return summary;
      }

    },

    // Helper functions for generating strings based on length
    shortenTitle(title) {
      let search_terms = this.$store.getters["search/getAllIncludeKeywords"].concat(this.$store.getters["search/getAllOrIncludeKeywords"])
      if (search_terms.length <= 0) {
        return truncateString(title, 280);
      }
      let current_user = this.$store.getters["accounts/getCurrentUser"];
      if (current_user.search_highlight) {
        var regexFromMyArray = new RegExp(search_terms.join("|"), 'gi');
        return truncateString(title, 280).replace(regexFromMyArray, match => {
          var colors = ['yellow'];
          var random_color = colors[Math.floor(Math.random() * colors.length)];
          return '<span style="background: ' + random_color + '">' + match + '</span>';
        });
      } else {
        return truncateString(title, 280);
      }

    },

    shortenSummary(summary) {
      let search_terms = this.$store.getters["search/getAllIncludeKeywords"].concat(this.$store.getters["search/getAllOrIncludeKeywords"])
      if (search_terms.length <= 0) {
        return truncateString(summary, 280);
      }
      let current_user = this.$store.getters["accounts/getCurrentUser"];
      if (current_user.search_highlight) {
        var regexFromMyArray = new RegExp(search_terms.join("|"), 'gi');
        return truncateString(summary, 280).replace(regexFromMyArray, match => {
          var colors = ['yellow'];
          var random_color = colors[Math.floor(Math.random() * colors.length)];
          return '<span style="background: ' + random_color + '">' + match + '</span>';
        });
      } else {
        return truncateString(summary, 280);
      }


    },

    createAgenciesString(allAgencies) {
      return createAgenciesString(allAgencies);
    },
    // End of helper functions for strings

    // Fire an event to display the Articles modal
    showArticleModal() {
      this.$store.dispatch('articles/updateModalContent', this.article);
      this.$store.dispatch('articles/updateModalShowingState', true);
    },

    // Used to open the modal and fire an event to log telemetry
    loadArticle(articleId) {
      logViewedArticle(articleId);
      this.showArticleModal();
      this.$tracking.trackEvent('ViewedArticle', {
        id: this.article.id,
        regalytics_alert_id: this.article.regalytics_alert_id,
        title: this.article.title,
        agencies: this.article.agencies,
        alert_type: this.article.alert_type,
        classification: this.article.classification,
        federal_register_number: this.article.federal_register_number,
        federal_register_publication_date: this.article.federal_register_publication_date,
        filing_type: this.article.filing_type,
        in_federal_register: this.article.in_federal_register,
        states: this.article.states,
        status: this.article.status
      });
    },

    goToSource(article) {
      this.$tracking.trackEvent('ViewArticleSource', {
        id: this.article.id,
        regalytics_alert_id: this.article.regalytics_alert_id,
        title: this.article.title,
        agencies: this.article.agencies,
        alert_type: this.article.alert_type,
        classification: this.article.classification,
        federal_register_number: this.article.federal_register_number,
        federal_register_publication_date: this.article.federal_register_publication_date,
        filing_type: this.article.filing_type,
        in_federal_register: this.article.in_federal_register,
        states: this.article.states,
        status: this.article.status
      });
      window.open(article.pdf_url, '_blank');
    }

  }

}
</script>

<style lang="scss" scoped>
.article-card {
  background-color: white;
  border-radius: .5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .1);
  -webkit-transition: all .3s ease-in-out;
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 15px;
  padding: .6rem 0;

  .ac-header {
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .ac-title {
    width: 100%;
  }

  .article-card-title {
    cursor: pointer;
  }

  .ac-body {
    padding: 0.2rem 1.5rem 0 1.5rem;
    cursor: pointer;
  }

  .social-icons {
    padding: 0 1.5rem 0.2rem 1.5rem;
    display: flex;
    align-content: center;
    justify-content: space-between;
  }
}

.article-alert-new {
  background: #4a3288 !important;
}

.article-alert-updated {
  background: #efbe06 !important;
}

.highlightText {
  background: yellow !important;
}
</style>
